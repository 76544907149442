import { useState, useEffect } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { useFetchMeasurementByIdQuery } from "./store/measurementsApiSlice";

import MeasurementHeader from "./MeasurementHeader";
import { Select, Space, Table, Tabs, Tag, Input } from "antd";
import { useDispatch, useSelector } from "react-redux";
import Button from "./shared/Button";
import MeasurementHistory from "./MeasurementHistory";
import {
  createNotification,
  selectActiveContextLayers,
  selectContextLayers,
  selectSites,
  selectSiteById,
} from "./store/applicationSlice";
import Badge from "./Badge";
import {
  selectIsCreatingNote,
  selectActiveOpticalLayer,
  selectOpticalLayers,
  updateActiveOpticalLayer,
  addTag,
  removeTag,
  createNote,
  clearError,
  fetchOptical,
  fetchMeasurementResources,
} from "./store/responseSlice";

import { ArrowUpOnSquareIcon } from "@heroicons/react/24/outline";
import { STATUS_COLORS } from "./constants";

import Loading from "./shared/Loading";
import MeasurementMap from "./MeasurementMap";
import { formatData, isNullish, convertToLocalTime } from "./utilities";
import { selectSession } from "./store/sessionSlice";

export default function MeasurementDetail() {
  const dispatch = useDispatch();
  const { measurementId } = useParams();
  const session = useSelector(selectSession);
  const {
    data: measurement,
    error,
    isLoading,
  } = useFetchMeasurementByIdQuery({
    accountId: session.account,
    measurementId,
  });
  const datasets = useSelector((state) => state.response.accountDatasets);
  const [contextModalOpen, toggleContextModal] = useState(false);
  const [mapOperationsModalOpen, toggleMapOperationsModal] = useState(false);
  const contextLayers = useSelector(selectContextLayers);
  const activeContextLayers = useSelector(selectActiveContextLayers);
  const activeOpticalLayer = useSelector(selectActiveOpticalLayer);
  const opticalLayers = useSelector(selectOpticalLayers);
  const opticalTokens = useSelector((state) => state.app.opticalTokens);
  const priorDetections = useSelector(
    (state) => state.response.priorDetections
  );
  const [hiddenPriorDetections, setHiddenPriorDetections] = useState([]);
  const site = useSelector((state) =>
    selectSiteById(state, measurement?.site_id)
  );
  const accountTags = useSelector((state) => state.response.accountTags);

  const [note, setNote] = useState("");
  const creatingNote = useSelector(selectIsCreatingNote);
  const [searchParameters] = useSearchParams();
  const sites = useSelector(selectSites);
  const navigate = useNavigate();
  const accountTagsByGroupId = useSelector(
    (state) => state.response.accountTagsByGroupId
  );

  const causesOptions = accountTags
    .filter((t) => t.group_id === "causes")
    .map(({ tag_id, tag_name }) => ({
      label: tag_name,
      value: tag_id,
    }));

  const statusOptions = accountTags
    .filter((t) => t.group_id === "status")
    .map((t) => ({ label: t.tag_name, value: t.tag_id }));

  useEffect(() => {
    if (measurement) {
      dispatch(fetchMeasurementResources(measurement));
    }
    setNote("");
  }, [dispatch, measurement]);

  useEffect(() => {
    if (error) {
      dispatch(
        createNotification({
          title: "Warning",
          description: error,
          type: "warning",
        })
      );
      dispatch(clearError());
    }
  }, [dispatch, error]);

  console.log({ measurement });

  if (isLoading) {
    return <Loading />;
  }

  const measurementStatus = measurement.tags.find(
    (t) => t.group_id === "status"
  )?.tag_name;

  const detectionClass = measurement.tags.find(
    (t) => t.group_id === "detection_class"
  )?.tag_name;

  function createShareLink() {
    const link = `${window.location}`;
    navigator.clipboard.writeText(link);
    dispatch(
      createNotification({
        title: "Measurement link copied to clipboard",
        description: "",
      })
    );
  }

  return (
    <div className="grid grid-cols-1 gap-2 md:grid-cols-10">
      <main className="md:col-span-7">
        <header className="sticky top-0 z-60 h-[68px] bg-gray-800 px-4 py-2 text-white">
          <MeasurementHeader
            site={site}
            measurement={measurement}
            datasets={datasets}
          />
        </header>
        <div className=" mb-4 h-[50vh] bg-white shadow-md">
          <MeasurementMap
            measurement={measurement}
            site={site}
            opticalTokens={opticalTokens}
            opticalLayers={opticalLayers}
            priorDetections={priorDetections}
            activeOpticalLayer={activeOpticalLayer}
            updateActiveOpticalLayer={updateActiveOpticalLayer}
            fetchOpticalLayers={fetchOptical}
            contextLayers={contextLayers}
            activeContextLayers={activeContextLayers}
            contextModalOpen={contextModalOpen}
            mapOperationsModalOpen={mapOperationsModalOpen}
            hiddenPriorDetections={hiddenPriorDetections}
            setHiddenPriorDetections={setHiddenPriorDetections}
            toggleContextModal={() => toggleContextModal((open) => !open)}
            toggleMapOperationsModal={() =>
              toggleMapOperationsModal((open) => !open)
            }
          />
        </div>
        <div className="mb-4 h-[40vh] bg-white shadow-md">
          <Tabs
            defaultActiveKey="historical-measurements"
            size="small"
            centered={true}
            type="line"
            items={[
              {
                label: "Historical Measurements",
                key: "historical-measurements",
                children: (
                  <div className="pb-8">
                    <Table
                      dataSource={priorDetections}
                      rowKey="measurement_id"
                      columns={[
                        {
                          title: "Time",
                          dataIndex: "measured_time",
                          render: (measuredTime, record) => {
                            const siteId = record.site_id;
                            const timezone = sites.find(
                              (s) => s.id === siteId
                            )?.timezone;
                            return convertToLocalTime(measuredTime, timezone);
                          },
                          onCell: () => ({
                            className:
                              "whitespace-nowrap overflow-hidden text-ellipsis max-w-[130px]",
                          }),
                        },
                        {
                          title: "Provider",
                          dataIndex: "dataset_id",
                          render: (datasetId) =>
                            datasets[datasetId]?.dataset_name,
                          onCell: () => ({
                            className:
                              "whitespace-nowrap overflow-hidden text-ellipsis max-w-[200px]",
                          }),
                        },
                        // {
                        //   title: "Site",
                        //   dataIndex: "site_id",
                        //   hidden: !!siteId,
                        //   render: (siteId) =>
                        //     sites.find((s) => s.id === siteId)?.name,
                        // },
                        {
                          title: "Value (kg/hr)",
                          dataIndex: "value",
                          key: "value",
                          render: (text, record) => {
                            const data = formatData(
                              record.value,
                              record.uncertainty_value
                            );
                            const value = data.value;
                            const uncertainty = data?.uncertainty_display;
                            return value
                              ? `${value}${
                                  !isNullish(uncertainty)
                                    ? ` ± ${uncertainty}%`
                                    : ""
                                }`
                              : "Non-detect";
                          },
                        },
                        ...accountTagsByGroupId.map((group) => ({
                          title: group.group_id
                            .split("_")
                            .map(
                              (word) =>
                                word.charAt(0).toUpperCase() + word.slice(1)
                            )
                            .join(" "),
                          key: group.group_id,
                          dataIndex: "tags",
                          render: (tags) => {
                            tags = tags.filter(
                              (tag) => tag.group_id === group.group_id
                            );
                            return (
                              <>
                                {tags.map((tag, idx) => {
                                  return (
                                    <Tag key={tag.tag_id + idx}>
                                      {tag.tag_name
                                        .toUpperCase()
                                        .substring(0, 20)}
                                    </Tag>
                                  );
                                })}
                              </>
                            );
                          },
                        })),
                      ]}
                      onRow={(record) => ({
                        onClick: () => {
                          navigate(
                            `/measurements/${record.measurement_id}?${searchParameters}`
                          );
                        },
                        style: { cursor: "pointer" },
                      })}
                    />
                  </div>
                ),
              },
              {
                label: "Gas System Data",
                key: "gas-system-data",
                children: (
                  <img
                    src="/gas-system-placeholder.svg"
                    alt=""
                    className="h-[35vh] w-full object-contain"
                  />
                ),
              },
            ]}
          />
        </div>
      </main>
      {/* Sidebar */}
      <aside className="sticky top-0 max-h-[100vh] bg-gray-100 p-4 shadow-md md:col-span-3">
        <div className="mb-4 flex justify-between">
          <h2 className="text-xl font-semibold">Response</h2>
          <Space size="small">
            <div className="space-x-1">
              {measurementStatus && (
                <Badge
                  color={STATUS_COLORS[measurementStatus.toUpperCase()]}
                  label={measurementStatus}
                />
              )}
              {detectionClass && <Badge label={detectionClass} />}
            </div>
            <ArrowUpOnSquareIcon
              className="h-6 w-6 cursor-pointer text-gray-400"
              title="Copy measurement link to share"
              onClick={() => createShareLink()}
            />
          </Space>
        </div>
        <h4 className="mb-4 text-lg">Emission status</h4>
        <div className="mb-4">
          <Select
            style={{
              width: "100%",
            }}
            placeholder="Please select a status for this measurement"
            value={measurementStatus}
            onSelect={(id) => dispatch(addTag({ tagId: id, measurementId }))}
            onDeselect={(id) =>
              dispatch(removeTag({ tagId: id, measurementId }))
            }
            options={statusOptions}
          />
        </div>
        <h4 className="mb-4 text-lg">Emission source</h4>
        <Select
          mode="multiple"
          allowClear
          style={{
            width: "100%",
          }}
          placeholder="Please select an emission source"
          value={measurement.tags
            .filter((t) => t.group_id === "causes")
            .map((t) => t.tag_id)}
          onSelect={(id) => dispatch(addTag({ tagId: id, measurementId }))}
          onDeselect={(id) => dispatch(removeTag({ tagId: id, measurementId }))}
          options={causesOptions}
        />
        <h4 className="my-4 text-lg">History</h4>
        <Input.TextArea
          rows={4}
          disabled={creatingNote}
          placeholder="Enter an optional note about the emission"
          onChange={(e) => setNote(e.target.value)}
          value={note}
        />
        <div className="my-2">
          <Button
            disabled={creatingNote}
            onClick={() => {
              if (note.length === 0) return;
              dispatch(createNote({ measurementId, content: note }));
              setNote("");
            }}
            fullWidth
            type="primary"
          >
            Submit
          </Button>
        </div>
        <hr className="my-2 h-px border-0 bg-gray-400" />
        <div className="my-2 ">
          <MeasurementHistory measurementId={measurementId} />
        </div>
      </aside>
    </div>
  );
}
