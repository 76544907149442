import { useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import ResponseMap from "./ResponseMap";
import { addTag } from "./store/responseSlice";
import { useDispatch, useSelector } from "react-redux";
import { Space, Table, Tag, Dropdown } from "antd";
import { useFetchMeasurementsQuery } from "./store/measurementsApiSlice";
import Loading from "./shared/Loading";
import { EllipsisVerticalIcon } from "@heroicons/react/24/outline";
import {
  selectActiveGrouping,
  selectActiveTimePeriod,
  selectSites,
  createNotification,
} from "./store/applicationSlice";
import { convertToLocalTime, formatData, isNullish } from "./utilities";
import Button from "./shared/Button";
import dayjs from "dayjs";
import JSURL from "jsurl";

function computeMeasurementOpenDays(record) {
  const measuredTime = dayjs(record.created_time);
  let compareTime = dayjs();
  const completeTag = record.tags.find(
    (t) => t.group_id === "status" && t.tag_id === "complete"
  );
  if (!isNullish(completeTag)) {
    compareTime = dayjs(completeTag.created_time);
  }
  return compareTime.diff(measuredTime, "days");
}

export default function Response() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { siteId } = useParams();
  const [searchParameters, setSearchParameters] = useSearchParams();
  const sites = useSelector(selectSites);
  const datasets = useSelector((state) => state.response.accountDatasets);
  const { startDate, endDate } = useSelector(selectActiveTimePeriod);
  const activeGrouping = useSelector(selectActiveGrouping);

  const [filteredMeasurements, setFilteredMeasurements] = useState([]);

  const options = {
    start: startDate,
    end: endDate,
  };
  if (siteId) {
    options.siteIds = [siteId];
  }
  if (activeGrouping) {
    options.siteIds = activeGrouping.sites;
  }
  const { data, isLoading } = useFetchMeasurementsQuery(options);
  const accountDatasetsLoaded = useSelector(
    (state) => state.response.accountDatasetsLoaded
  );
  const accountTagsByGroupId = useSelector(
    (state) => state.response.accountTagsByGroupId
  );

  // TODO: it feels like there should be a better way to do this
  const initialTableParams = searchParameters.keys().reduce((acc, key) => {
    const val = searchParameters.get(key);
    if (val.startsWith("~")) {
      acc[key] = JSURL.parse(val);
    } else {
      acc[key] = val;
    }
    return acc;
  }, {});

  function createMeasurementShareLink(measurementId) {
    return `${window.location.origin}/measurements/${measurementId}`;
  }

  function createListShareLink() {
    const link = `${window.location}`;
    navigator.clipboard.writeText(link);
    dispatch(
      createNotification({
        title: "Link copied to clipboard",
        description: "",
      })
    );
  }

  const handleExport = () => {
    const csvContent = generateCSV(data.measurements);
    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.setAttribute("href", url);
    link.setAttribute("download", "measurements.csv");
    link.style.visibility = "hidden";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const escapeCSVValue = (value) => {
    if (typeof value === "string" && value.includes(",")) {
      return `"${value}"`;
    }
    return value;
  };

  const generateCSV = (data) => {
    const headers = [
      "Measurement Time UTC",
      "Measurement Time Local",
      "Provider",
      "Site",
      "Value (kg/hr)",
      "Uncertainty (%)",
    ];

    accountTagsByGroupId.forEach((group) => {
      headers.push(
        group.group_id
          .split("_")
          .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
          .join(" ")
      );
    });

    const rows = data.map((measurement) => {
      const site = sites.find((s) => s.id === measurement.site_id);
      const timezone = site?.timezone || "";
      const measured_time_local = convertToLocalTime(
        measurement.measured_time,
        timezone
      );
      const provider = datasets[measurement.dataset_id]?.dataset_name || "";
      const siteName = site?.name || "";
      const { value, uncertainty_display } = formatData(
        measurement.value,
        measurement.uncertainty_value
      );
      const row = [
        measurement.measured_time,
        measured_time_local,
        provider,
        siteName,
        value,
        uncertainty_display,
      ];

      accountTagsByGroupId.forEach((group) => {
        const tags = measurement.tags.filter(
          (tag) => tag.group_id === group.group_id
        );
        row.push(tags.map((tag) => tag.tag_name).join(", "));
      });

      return row.map(escapeCSVValue).join(",");
    });

    return [headers.join(","), ...rows].join("\n");
  };

  function sortOrderForField(field) {
    return field === initialTableParams["sort_field"]
      ? initialTableParams["sort_order"]
      : null;
  }

  function filterValueForField(field) {
    if (!isNullish(initialTableParams[field])) {
      return initialTableParams[field];
    }
    return null;
  }

  if (isLoading || !accountDatasetsLoaded) {
    return <Loading />;
  }

  return (
    <main className="min-h-screen bg-bsr-gray-100 px-10">
      <div className="mb-3 mt-5 flex items-start justify-between">
        <h2 className="mb-5 inline-flex items-center text-2xl leading-7 text-bsr-gray-700 sm:truncate sm:text-3xl">
          <span className="font-bold">Emission response</span>
        </h2>
        <div>
          <Space size="small">
            <Button onClick={handleExport} type="white">
              Export
            </Button>
            <Button onClick={createListShareLink} type="white">
              Share
            </Button>
          </Space>
          {/*
          <Segmented
            size="large"
            options={["Needs review", "In progress", "Complete", "All"]}
            onChange={setFilter}
            defaultValue={filter}
          />*/}
        </div>
      </div>
      <div className="my-5 h-[45vh] w-full overflow-hidden rounded-lg">
        <ResponseMap
          x={parseFloat(searchParameters.get("x"))}
          y={parseFloat(searchParameters.get("y"))}
          z={parseFloat(searchParameters.get("z"))}
          searchParameters={searchParameters}
          setSearchParameters={setSearchParameters}
          measurements={
            filteredMeasurements.length
              ? filteredMeasurements
              : data.measurements
          }
          navigate={navigate}
          locations={data.locations}
        />
      </div>
      <div className="pb-8">
        <Table
          rowKey="measurement_id"
          columns={[
            {
              title: "Time",
              dataIndex: "measured_time",
              defaultSortOrder: sortOrderForField("measured_time"),
              render: (measuredTime, record) => {
                const siteId = record.site_id;
                const timezone = sites.find((s) => s.id === siteId)?.timezone;
                return convertToLocalTime(measuredTime, timezone);
              },
              onCell: () => ({
                className:
                  "whitespace-nowrap overflow-hidden text-ellipsis max-w-[130px]",
              }),
              sorter: (a, b) =>
                new Date(a.measured_time) - new Date(b.measured_time),
            },
            {
              title: "Provider",
              dataIndex: "dataset_id",
              defaultFilteredValue: filterValueForField("dataset_id"),
              render: (datasetId) => datasets[datasetId]?.dataset_name,
              filters: Array.from(
                new Set(data.measurements.map((m) => m.dataset_id))
              ).map((id) => ({
                text: datasets[id].dataset_name,
                value: id,
              })),
              onFilter: (value, record) => record.dataset_id === value,
              onCell: () => ({
                className:
                  "whitespace-nowrap overflow-hidden text-ellipsis max-w-[200px]",
              }),
            },
            {
              title: "Site",
              dataIndex: "site_id",
              hidden: !!siteId,
              render: (siteId) => sites.find((s) => s.id === siteId)?.name,
            },
            {
              title: "Value (kg/hr)",
              dataIndex: "value",
              key: "value",
              defaultSortOrder: sortOrderForField("value"),
              sorter: (a, b) => a.value - b.value,
              render: (text, record) => {
                const data = formatData(record.value, record.uncertainty_value);
                const value = data.value;
                const uncertainty = data?.uncertainty_display;
                return value
                  ? `${value}${
                      !isNullish(uncertainty) ? ` ± ${uncertainty}%` : ""
                    }`
                  : "Non-detect";
              },
            },
            ...accountTagsByGroupId.map((group) => ({
              title: group.group_id
                .split("_")
                .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                .join(" "),
              key: group.group_id,
              dataIndex: "tags",
              filters: group.options.map((o) => ({
                text: o.tag_name,
                value: o.tag_name,
              })),
              defaultFilteredValue: filterValueForField(group.group_id),
              onFilter: (value, record) => {
                return record.tags.find(
                  (t) => t.tag_name.toUpperCase() === value.toUpperCase()
                );
              },
              render: (tags) => {
                tags = tags.filter((tag) => tag.group_id === group.group_id);
                return (
                  <>
                    {tags.map((tag, idx) => {
                      return (
                        <Tag key={tag.tag_id + idx}>
                          {tag.tag_name.toUpperCase().substring(0, 20)}
                        </Tag>
                      );
                    })}
                  </>
                );
              },
            })),
            {
              title: "Days Open",
              key: "days_open",
              defaultSortOrder: sortOrderForField("days_open"),
              sorter: (a, b) => {
                return (
                  computeMeasurementOpenDays(a) - computeMeasurementOpenDays(b)
                );
              },
              render: (record) => {
                return computeMeasurementOpenDays(record);
              },
            },
            {
              title: "Actions",
              key: "actions",
              render: (record) => (
                <Dropdown
                  trigger={["click"]}
                  menu={{
                    items: [
                      {
                        key: "share",
                        label: "Share",
                        onClick: (e) => {
                          e.domEvent.stopPropagation();
                          navigator.clipboard.writeText(
                            createMeasurementShareLink(record.measurement_id)
                          );
                          dispatch(
                            createNotification({
                              title: "Measurement link copied to clipboard",
                              description: "",
                            })
                          );
                        },
                      },
                      {
                        key: "mark-needs-review",
                        label: "Mark Needs Review",
                        disabled: record.tags.find(
                          (t) =>
                            t.group_id === "status" &&
                            t.tag_id === "needs_review"
                        ),
                        onClick: (e) => {
                          e.domEvent.stopPropagation();
                          dispatch(
                            addTag({
                              id: "needs_review",
                              measurementId: record.measurement_id,
                            })
                          );
                          dispatch(
                            createNotification({
                              title: "Set status to Needs Review",
                              description: "",
                            })
                          );
                        },
                      },
                      {
                        key: "mark-in-progress",
                        label: "Mark In Progress",
                        disabled: record.tags.find(
                          (t) =>
                            t.group_id === "status" &&
                            t.tag_id === "in_progress"
                        ),
                        onClick: (e) => {
                          e.domEvent.stopPropagation();
                          dispatch(
                            addTag({
                              id: "in_progress",
                              measurementId: record.measurement_id,
                            })
                          );
                          dispatch(
                            createNotification({
                              title: "Set status to In Progress",
                              description: "",
                            })
                          );
                        },
                      },
                      {
                        key: "mark-complete",
                        label: "Mark Complete",
                        disabled: record.tags.find(
                          (t) =>
                            t.group_id === "status" && t.tag_id === "complete"
                        ),
                        onClick: (e) => {
                          e.domEvent.stopPropagation();
                          dispatch(
                            addTag({
                              id: "complete",
                              measurementId: record.measurement_id,
                            })
                          );
                          dispatch(
                            createNotification({
                              title: "Set status to Complete",
                              description: "",
                            })
                          );
                        },
                      },
                    ],
                  }}
                >
                  <EllipsisVerticalIcon
                    className="h-5 w-5 text-gray-400"
                    onClick={(e) => e.stopPropagation()}
                  />
                </Dropdown>
              ),
            },
          ]}
          dataSource={data.measurements}
          onChange={(pagination, filters, sorter, extra) => {
            setFilteredMeasurements(extra.currentDataSource);
            setSearchParameters((sp) => {
              Object.keys(filters).forEach((key) => {
                if (!isNullish(filters[key])) {
                  sp.set(key, JSURL.stringify(filters[key]));
                } else {
                  sp.delete(key);
                }
              });
              return sp;
            });

            if (!isNullish(sorter.column)) {
              setSearchParameters((sp) => {
                sp.set("sort_field", JSURL.stringify(sorter.columnKey));
                sp.set("sort_order", JSURL.stringify(sorter.order));
                return sp;
              });
            }
          }}
          onRow={(record) => ({
            onClick: () => {
              navigate(
                `/measurements/${record.measurement_id}?${searchParameters}`
              );
            },
            style: { cursor: "pointer" },
          })}
        />
      </div>
    </main>
  );
}
