import React from "react";
import { auth } from "./shared/firebase";
import { GoogleMapsOverlay as DeckOverlay } from "@deck.gl/google-maps";
import dayjs from "./dayjs";
import { Popover } from "@headlessui/react";
import { TileLayer } from "@deck.gl/geo-layers";
import { BitmapLayer } from "deck.gl";
import api from "./services/Api";
import constructBaseGeojsonLayer from "./layers/geojson";
import OpticalLayersPopup from "./OpticalLayersPopup";
import ContextLayersPopup from "./ContextLayersPopup";
import MeasurementMapOperationsPopup from "./MeasurementMapOperationsPopup";
import constructBaseTileLayer from "./layers/tms";
import { isEqual } from "lodash";
import {
  getHtml,
  isTokenExpired,
  parseWktPoint,
  getGeometryCentroidLatLon,
  updateUrlParameter,
  classNames,
  toggleLayerVisibility,
  formatMeasurementValue,
} from "./utilities";
import { feature, featureCollection } from "@turf/helpers";
//import MeasurementContextLayers from "./MeasurementContextLayers";
// import LoadingOverlay from "./shared/LoadingOverlay";
// import WindWidget from "./WindWidget";
import { Square3Stack3DIcon, QueueListIcon } from "@heroicons/react/24/solid";
import streetPng from "./assets/street.png";
import satellitePng from "./assets/satellite.png";
import terrainPng from "./assets/terrain.png";

class MeasurementMap extends React.Component {
  constructor(props) {
    super(props);
    this.mapRef = React.createRef();
    this.state = {
      loading: false,
    };
    this.overlay = new DeckOverlay({
      layers: [],
      getTooltip: ({ object }) => {
        if (object && object.properties) {
          return {
            html: `<div>${getHtml({
              ...object.properties,
              geometry: object.geometry,
            })}</div>`,
            style: {
              backgroundColor: "#1F2933",
              whiteSpace: "nowrap",
              fontSize: "1.5em",
              padding: "5px",
              color: "white",
            },
          };
        }
      },
    });
  }

  async initMap() {
    const { Map } = await window.google.maps.importLibrary("maps");

    const { measurement, site } = this.props;

    // if there is no measurement location, check the site geometry and use its centroid
    const { lat, lng } = parseWktPoint(measurement.location_wkt) ||
      getGeometryCentroidLatLon(site.geometry) || {
        lat: 40.015,
        lng: -105.2705,
      };

    if (this.mapRef.current) {
      this.map = new Map(this.mapRef.current, {
        center: { lat, lng },
        zoom: 15,
        tilt: 0,
        mapTypeId: "satellite",
        mapTypeControlOptions: {
          mapTypeIds: ["roadmap", "terrain", "satellite", "hybrid"],
          position: window.google.maps.ControlPosition.TOP_RIGHT,
        },
        scaleControl: true,
        fullscreenControl: false,
        streetViewControl: false,
        rotateControl: false,
        mapTypeControl: false,
        zoomControl: false,
      });
    }
  }

  getMapType() {
    if (this.map) {
      return this.map.getMapTypeId();
    }
  }

  getLayerThumbnail() {
    if (this.props.activeOpticalLayer) {
      return this.props.activeOpticalLayer.provider === "planet"
        ? `${this.props.activeOpticalLayer.thumbnail_link}?api_key=${this.props.opticalTokens["planet"]}`
        : this.props.activeOpticalLayer.thumbnail_link;
    } else {
      const imageMap = {
        roadmap: streetPng,
        satellite: satellitePng,
        terrain: terrainPng,
      };
      return imageMap[this.getMapType()];
    }
  }

  constructContextLayer(contextLayer) {
    console.log({ contextLayer });
    let data = contextLayer.data;

    if (data.features) {
      data = featureCollection(
        data.features.map((feature) => {
          const mutable = JSON.parse(JSON.stringify(feature));
          mutable.properties.layerTypeV2 = "ContextLayer";
          return mutable;
        })
      );
    }

    if (
      contextLayer.category === "LFG Extraction Wells" ||
      contextLayer.category === "GCCS Control Devices"
    ) {
      return constructBaseGeojsonLayer(data, {
        id: `context-layer-${contextLayer.id}`,
        filled: true,
        stroked: true,
        getLineColor: [255, 255, 255],
        getFillColor: () => contextLayer.color,
      });
    } else if (data.type === "MultiPolygon") {
      return constructBaseGeojsonLayer(data, {
        id: `context-layer-${contextLayer.id}`,
        filled: true,
        stroked: true,
        getLineColor: () =>
          contextLayer.color
            ? [...contextLayer.color, 192]
            : [28, 212, 212, 192],
        // multi-polygons need some fill to distinguish
        // inner & outer boundaries of multi-polygon.
        getFillColor: () => [...contextLayer.color, 50],
      });
    } else {
      return constructBaseGeojsonLayer(data, {
        id: `context-layer-${contextLayer.id}`,
        filled: false,
        stroked: true,
        getLineColor: () =>
          contextLayer.color
            ? [...contextLayer.color, 192]
            : [28, 212, 212, 192],
        // hack to increase onHover surface area
        getFillColor: () => [0, 0, 0, 0],
        getLineWidth: 5,
        getElevation: 30,
      });
    }
  }

  async loadMeasurement(measurement, options = {}) {
    console.log(
      `loading${options.priorDetection ? " prior " : " primary "}detect ${
        measurement.measurement_id
      }`
    );

    let plumeOriginLayer;
    let tileLayer;

    const percentError = (
      (measurement?.uncertainty_value / measurement?.value) *
      100
    ).toFixed(1);

    const properties = {
      value: formatMeasurementValue(measurement),
    };

    console.log({ measurement });

    if (measurement?.uncertainty_value) {
      properties["uncertainty"] = `±${percentError}%`;
    }
    if (options.priorDetection) {
      properties["date"] = dayjs(measurement.measured_time).format(
        "MM-DD-YYYY hh:mm A"
      );
    }
    let data;

    try {
      const { geojson } = parseWktPoint(measurement.location_wkt);
      data = feature(geojson, properties);
    } catch (err) {
      console.log(err);
    }

    plumeOriginLayer = constructBaseGeojsonLayer(data, {
      id: `geojson-plume-origin-${measurement.measurement_id}`,
      getFillColor: options.priorDetection
        ? [39, 144, 245, 250]
        : [218, 18, 125, 250],
      plumeOriginLayer: true,
      onClick: () => {
        toggleLayerVisibility(
          this.overlay,
          `plume-layer-${measurement.measurement_id}`
        );
      },
    });
    if (options.priorDetection) {
      return [plumeOriginLayer];
    }

    try {
      // Retrieve the URL for the measurement from the API
      const { url } = await api.getMeasurementById({
        measurementId: measurement.measurement_id,
        datasetId: measurement.dataset_id,
      });

      tileLayer = new TileLayer({
        id: `plume-layer-${measurement.measurement_id}`,
        visible: false,
        data: url,
        tileSize: 512, // Adjust based on your tile size
        minZoom: 0,
        maxZoom: 18,
        renderSubLayers: (props) => {
          const {
            bbox: { west, south, east, north },
          } = props.tile;
          return new BitmapLayer(props, {
            data: null,
            image: props.data,
            bounds: [west, south, east, north],
          });
        },
      });

      return [tileLayer, plumeOriginLayer];
    } catch (error) {
      // Handle any errors that occurred during the API call or adding the TileLayer
      console.error("Failed to load plume layer:", error);
    }

    return [tileLayer, plumeOriginLayer];
  }

  async componentDidMount() {
    await this.initMap();
    // ONE TIME SYNC GMAPS INSTANCE WITH DECK
    this.overlay.setMap(this.map);
    this.renderLayers();
  }

  componentDidUpdate(prevProps) {
    if (
      !isEqual(prevProps.activeOpticalLayer, this.props.activeOpticalLayer) ||
      !isEqual(prevProps.activeContextLayers, this.props.activeContextLayers) ||
      !isEqual(prevProps.priorDetections, this.props.priorDetections)
    ) {
      this.renderLayers();
    }
  }

  componentWillUnmount() {
    this.map = null;
    this.overlay.finalize();
  }

  async constructOpticalBasemapLayer({ id, xyz_link, provider }) {
    console.log("constructing optical layer");
    let xyz = xyz_link;
    const userToken = await auth.currentUser.getIdToken();
    let opticalProviderToken = this.props.opticalTokens[provider];
    if (provider === "airbus" && isTokenExpired(opticalProviderToken)) {
      opticalProviderToken = await fetch(
        `${process.env.REACT_APP_AIRLOGIC_API_URL}/tokens/${provider}`,
        {
          method: "GET",
          mode: "cors",
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        }
      ).then((response) => response.text());
    }

    if (provider === "maxar") {
      if (xyz.includes("maxar_api_key")) {
        xyz = updateUrlParameter(xyz, "maxar_api_key", opticalProviderToken);
      } else {
        xyz = `${xyz}&maxar_api_key=${opticalProviderToken}`;
      }
    }

    if (provider === "planet") {
      xyz = `${xyz_link}?api_key=${opticalProviderToken}`;
    }

    const options = {
      id,
    };

    if (provider === "airbus") {
      options.loadOptions = {
        fetch: {
          method: "GET",
          mode: "cors",
          headers: {
            Authorization: `Bearer ${opticalProviderToken}`,
          },
        },
      };
    }

    return constructBaseTileLayer(xyz, options);
  }

  async renderLayers() {
    let layers = [];
    this.setState({ loading: true });
    if (this.props.activeOpticalLayer) {
      layers.push(
        this.constructOpticalBasemapLayer({
          id: this.props.activeOpticalLayer.id,
          xyz_link: this.props.activeOpticalLayer.xyz_link,
          provider: this.props.activeOpticalLayer.provider,
        })
      );
    }
    if (this.props.priorDetections) {
      this.props.priorDetections.forEach((m) => {
        if (
          m.measurement_id !== this.props.measurement.measurement_id &&
          !this.props.hiddenPriorDetections.includes(m.measurement_id)
        ) {
          layers.push(this.loadMeasurement(m, { priorDetection: true }));
        }
      });
    }

    this.props.activeContextLayers.forEach((layer) => {
      layers.push(this.constructContextLayer(layer));
    });

    layers.push(this.loadMeasurement(this.props.measurement));

    // loop through all layers and wrap everything in a promise so we can safely
    // use Promise.all() API to wait for both sync and async layers
    layers = layers.map((layer) => new Promise((resolve) => resolve(layer)));

    // some constructLayer functions return an array of deck.gl layers so we need to
    // flatten the array before visualizing with .flat()
    const resolvedLayers = await Promise.all(layers).then((layers) =>
      layers.flat()
    );

    this.overlay.setProps({
      layers: [...resolvedLayers],
    });

    this.setState({ loading: false });
  }

  render() {
    return (
      <div className="relative h-full w-full">
        <div className="h-full w-full" ref={this.mapRef}></div>
        {/* <MeasurementContextLayers /> */}
        {/* <div className="absolute bottom-[35px] left-[12px]">
          <WindWidget />
        </div> */}
        <div
          onClick={this.props.toggleContextModal}
          className={classNames(
            this.props.contextModalOpen ||
              this.props.activeContextLayers.length > 0
              ? "bg-bsr-blue-400"
              : "bg-white opacity-60 hover:bg-bsr-gray-200",
            "absolute left-3 top-3 flex h-9 w-9 cursor-pointer items-center justify-center rounded-sm"
          )}
        >
          <Square3Stack3DIcon
            className={classNames(
              this.props.contextModalOpen ||
                this.props.activeContextLayers.length > 0
                ? "text-white"
                : "text-bsr-gray-500",
              "h-8 w-8"
            )}
          />
        </div>
        {this.props.contextModalOpen && (
          <div className="no-scrollbar absolute left-12 top-7 z-60 max-h-[350px] overflow-y-scroll rounded-md bg-bsr-gray-050">
            <ContextLayersPopup contextLayers={this.props.contextLayers} />
          </div>
        )}

        <div
          onClick={this.props.toggleMapOperationsModal}
          className={classNames(
            "bg-white opacity-60 hover:bg-bsr-gray-200",
            "absolute bottom-8 left-3 flex h-9 w-9 cursor-pointer items-center justify-center rounded-sm"
          )}
        >
          <QueueListIcon
            className={classNames(
              this.props.mapOperationsModalOpen || "text-bsr-gray-500",
              "h-8 w-8"
            )}
          />
        </div>
        {this.props.mapOperationsModalOpen && (
          <div className="no-scrollbar absolute bottom-12 left-12 z-60 max-h-[350px] overflow-y-scroll rounded-md bg-bsr-gray-050">
            <MeasurementMapOperationsPopup
              overlay={this.overlay}
              plumeLayerId={`plume-layer-${this.props.measurement.measurement_id}`}
            />
          </div>
        )}

        <Popover className="absolute right-3 top-3 z-50">
          <Popover.Button className="absolute right-0 top-0">
            <div
              style={{
                backgroundImage: `linear-gradient(to top, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0) 100%),
              url(${this.getLayerThumbnail()})`,
                backgroundSize: this.props.activeOpticalLayer
                  ? "300%"
                  : "cover",
                backgroundPosition: "center",
              }}
              className="box-border flex h-[75px] w-[75px] cursor-pointer flex-col justify-end rounded-md border border-white text-xs text-white hover:border-2"
            >
              {this.props.activeOpticalLayer ? (
                <div className="flex h-full flex-col items-center justify-between p-1">
                  <div className="flex justify-center font-bold">Basemap</div>
                  <div>
                    {dayjs(this.props.activeOpticalLayer.date).format(
                      "M-D-YYYY"
                    )}
                  </div>
                </div>
              ) : (
                <div className="mt-auto flex justify-center p-1">
                  {this.getMapType()}
                </div>
              )}
            </div>
          </Popover.Button>
          <Popover.Panel className="relative right-[90px] z-50">
            {({ close }) => {
              return (
                <OpticalLayersPopup
                  site={this.props.site}
                  activeOpticalLayer={this.props.activeOpticalLayer}
                  opticalLayers={this.props.opticalLayers}
                  updateActiveOpticalLayer={this.props.updateActiveOpticalLayer}
                  fetchOpticalLayers={this.props.fetchOpticalLayers}
                  measurement={this.props.measurement}
                  getMapType={this.getMapType}
                  mapRef={this.map}
                  closeModal={close}
                />
              );
            }}
          </Popover.Panel>
        </Popover>
        {/* {this.state.loading && <LoadingOverlay />} */}
      </div>
    );
  }
}

export default MeasurementMap;
