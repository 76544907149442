import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { measurementsApi } from "./measurementsApiSlice";
import { parseWktPoint } from "../utilities";
import dayjs from "../dayjs";

import api, {
  getAccountTags,
  addTagToMeasurement,
  removeTagFromMeasurement,
  addNoteToMeasurement,
  getAccountDatasets,
} from "../services/Api";
import { fetchContextLayers } from "./applicationSlice";

const initialState = {
  measurements: [],
  measurementsLoaded: false,
  locations: [],
  priorDetections: [],
  priorDetectionsFetched: false,
  measurement: null,
  measurementResourcesFetched: false,
  measurementLoaded: false,
  creatingNote: false,
  accountTags: [],
  accountTagsByGroupId: [],
  accountDatasets: [],
  accountTagsLoaded: false,
  accountDatasetsLoaded: false,
  contextLayers: [],
  activeContextLayers: [],
  activeOpticalLayer: null,
  opticalLayers: [],
  error: null,
};

export const fetchMeasurementResources = createAsyncThunk(
  "response/fetchMeasurementResources",
  async (measurement, { dispatch, getState }) => {
    const {
      app: { sites },
      session: {
        data: { account },
      },
    } = getState();

    const site = sites.find((s) => s.id === measurement.site_id);
    await Promise.allSettled([
      dispatch(fetchPriorDetections({ account, measurement })),
      dispatch(fetchOptical({ measurement, autoSelect: true })),
      dispatch(fetchContextLayers(site)),
    ]);

    return measurement;
  }
);

export const fetchOptical = createAsyncThunk(
  "response/fetchOptical",
  async ({ measurement, autoSelect }) => {
    const start = dayjs(measurement.measured_time)
      .subtract(100, "days")
      .toISOString();
    const end = dayjs(measurement.measured_time).add(100, "days").toISOString();
    const options = { siteId: measurement.site_id, start, end };
    const { lat, lng } = parseWktPoint(measurement.location_wkt);
    if (lat && lng) {
      options.lat = lat;
      options.lon = lng;
    }

    const payload = await api.fetchOptical(options).then((images) => {
      if (
        images.length > 0 &&
        autoSelect &&
        process.env.REACT_APP_AUTO_SELECT_OPTICAL === "on"
      ) {
        console.log({ images });
        // Sort images by resolution (descending) and then by distance to measuredTime (ascending)
        const sortedImages = images.slice().sort((a, b) => {
          if (a.resolution === b.resolution) {
            // If resolutions are the same, sort by date difference
            const aDiff = Math.abs(
              new Date(a.date) - new Date(measurement.measured_time)
            );
            const bDiff = Math.abs(
              new Date(b.date) - new Date(measurement.measured_time)
            );
            return aDiff - bDiff; // Closer date first
          }
          return a.resolution - b.resolution; // Higher resolution first
        });

        console.log({ sortedImages });
        // highest resolution image that is closest to measured_time
        return { all: sortedImages, selected: sortedImages[0], autoSelect };
      } else {
        console.log({ images });
        return { all: images, autoSelect };
      }
    });

    return payload;
  }
);

export const createNote = createAsyncThunk(
  "response/createNote",
  async ({ measurementId, content }, { dispatch }) => {
    await addNoteToMeasurement({ measurementId, content });
    dispatch(
      measurementsApi.util.invalidateTags([
        { type: "Measurement", id: measurementId },
      ])
    );
  }
);

export const fetchMeasurements = createAsyncThunk(
  "response/fetchMeasurements",
  async (options) => {
    try {
      return api.fetchMeasurements(options);
    } catch (err) {
      throw new Error("failed to fetch measurements");
    }
  }
);

export const fetchPriorDetections = createAsyncThunk(
  "response/fetchPriorDetections",
  async ({ account, measurement }) => {
    // TODO another account piece of logic to refactor out
    const locationType = account !== "chevron" ? "site" : "location_group";
    const locationId = measurement.locations.find(
      (l) => l.location_type === locationType
    )?.location_id;
    const end = measurement.measured_time;
    try {
      if (!locationId) return { measurements: [] };
      return api.fetchPriorDetections(locationId, end);
    } catch (err) {
      throw new Error("failed to fetch prior detections");
    }
  }
);

export const fetchAccountTags = createAsyncThunk(
  "response/fetchAccountTags",
  async () => {
    const accountTags = await getAccountTags();
    return accountTags;
  }
);

export const fetchAccountDatasets = createAsyncThunk(
  "response/fetchAccountDatasets",
  async () => {
    const accountDatasets = await getAccountDatasets();
    return accountDatasets;
  }
);

export const addTag = createAsyncThunk(
  "response/addTagToMeasurement",
  async ({ tagId, measurementId }, { getState, dispatch }) => {
    const accountTags = getState().response.accountTags;
    const tagDef = accountTags.find((t) => t.tag_id === tagId);

    if (!tagDef) {
      throw new Error(`No tag definition found for tag_id: ${tagId}`);
    }
    const tag = {
      ...tagDef,
      created_time: new Date(),
      modified_time: new Date(),
    };
    const isStatusUpdate = tag.group_id === "status";
    await addTagToMeasurement(measurementId, tag, isStatusUpdate);
    // Manually invalidate the tag after the update
    dispatch(
      measurementsApi.util.invalidateTags([
        { type: "Measurement", id: measurementId },
      ])
    );
  }
);

export const removeTag = createAsyncThunk(
  "response/removeTagFromMeasurement",
  async ({ tagId, measurementId }, { getState, dispatch }) => {
    const accountTags = getState().response.accountTags;
    const tagDef = accountTags.find((t) => t.tag_id === tagId);
    if (!tagDef) {
      throw new Error(`No tag definition found for tag_id: ${tagId}`);
    }
    const isStatusUpdate = tagDef.group_id === "status";
    await removeTagFromMeasurement(
      measurementId,
      tagDef.tag_id,
      isStatusUpdate
    );
    dispatch(
      measurementsApi.util.invalidateTags([
        { type: "Measurement", id: measurementId },
      ])
    );
  }
);

export const responseSlice = createSlice({
  name: "response",
  initialState,
  reducers: {
    clearError: (state) => {
      state.error = null;
    },
    setMeasurement: (state, action) => {
      state.measurement = action.payload;
    },
    updateActiveOpticalLayer: (state, action) => {
      if (action.payload) {
        state.activeOpticalLayer = action.payload;
      } else {
        state.activeOpticalLayer = null;
      }
    },
  },
  extraReducers: {
    [fetchMeasurementResources.fulfilled]: (state, action) => {
      state.measurement = action.payload;
      state.measurementResourcesFetched = true;
    },
    [fetchMeasurementResources.pending]: (state) => {
      state.measurement = null;
      state.priorDetections = [];
      state.measurementResourcesFetched = false;
    },
    [fetchMeasurements.fulfilled]: (state, action) => {
      state.measurements = action.payload.measurements;
      state.locations = action.payload.locations;
      state.measurementsLoaded = true;
    },
    [fetchMeasurements.pending]: (state) => {
      state.measurements = [];
      state.measurementsLoaded = false;
    },
    [fetchOptical.fulfilled]: (state, action) => {
      if (action.payload.autoSelect) {
        state.activeOpticalLayer = action.payload.selected;
      }
      state.opticalLayers = action.payload.all;
    },
    [fetchPriorDetections.fulfilled]: (state, action) => {
      state.priorDetections = action.payload.measurements;
      state.priorDetectionsFetched = true;
    },
    [fetchPriorDetections.pending]: (state) => {
      state.priorDetections = [];
    },
    [fetchPriorDetections.rejected]: (state) => {
      state.priorDetectionsFetched = true;
    },
    [fetchAccountTags.fulfilled]: (state, action) => {
      state.accountTags = action.payload;
      state.accountTagsByGroupId = action.payload.reduce((acc, cur) => {
        const groupExists = acc.find(
          (group) => group.group_id === cur.group_id
        );

        if (!groupExists) {
          // creating an array of unique tags by group_id and then
          // populating options array with all possible tags for that group
          acc.push({
            group_id: cur.group_id,
            tag_name: cur.tag_name,
            tag_id: cur.tag_id,
            options: action.payload.filter((t) => t.group_id === cur.group_id),
          });
        }

        return acc;
      }, []);
      state.accountTagsLoaded = true;
    },
    [fetchAccountDatasets.fulfilled]: (state, action) => {
      state.accountDatasets = action.payload;
      state.accountDatasetsLoaded = true;
    },
    [createNote.pending]: (state) => {
      state.creatingNote = true;
    },
    [createNote.fulfilled]: (state) => {
      state.creatingNote = false;
    },
    [fetchAccountTags.rejected]: (state) => {
      state.error = "Operation fetch account tags failed";
      state.accountTagsLoaded = false;
    },
    [createNote.rejected]: (state) => {
      state.error = "Operation create note failed";
    },
    [addTag.rejected]: (state) => {
      state.error = "Operation add tag failed";
    },
    [removeTag.rejected]: (state) => {
      state.error = "Operation remove tag failed";
    },
  },
});

export const { clearError, updateActiveOpticalLayer, setMeasurement } =
  responseSlice.actions;

export const selectDataLoaded = (state) => {
  return (
    state.response.measurementLoaded &&
    state.response.accountTagsLoaded &&
    state.response.accountDatasetsLoaded
  );
};
export const selectIsCreatingNote = (state) => state.response.creatingNote;
export const selectMeasurement = (state) => state.response.measurement;
export const selectContextLayers = (state) => state.response.contextLayers;
export const selectActiveContextLayers = (state) =>
  state.response.activeContextLayers;

export const selectActiveOpticalLayer = (state) =>
  state.response.activeOpticalLayer;

export const selectOpticalLayers = (state) => state.response.opticalLayers;
export const selectAccountTags = (state) => state.response.accountTags;

export default responseSlice.reducer;
