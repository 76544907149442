import React from "react";

const Badge = ({ color = "gray", label = "Badge" }) => {
  // Mapping of color props to corresponding Tailwind classes
  const colorMap = {
    green: {
      bg: "bg-bsr-teal-100",
      text: "text-bsr-teal-700",
      ring: "ring-bsr-teal-500/10",
    },
    yellow: {
      bg: "bg-bsr-yellow-100",
      text: "text-bsr-yellow-700",
      ring: "ring-bsr-yellow-500/10",
    },
    blue: {
      bg: "bg-bsr-blue-100",
      text: "text-bsr-blue-700",
      ring: "ring-bsr-blue-500/10",
    },
    red: {
      bg: "bg-bsr-red-100",
      text: "text-bsr-red-700",
      ring: "ring-bsr-red-500/10",
    },
    gray: {
      bg: "bg-bsr-gray-100",
      text: "text-bsr-gray-600",
      ring: "ring-bsr-gray-500/10",
    },
    pink: {
      bg: "bg-bsr-pink-100",
      text: "text-bsr-pink-700",
      ring: "ring-bsr-pink-500/10",
    },
  };

  const selectedColor = colorMap[color] || colorMap.gray; // Default to gray if color is not in the map

  return (
    <span
      className={`inline-flex items-center rounded-md ${selectedColor.bg} px-2 py-1 text-xs font-medium ${selectedColor.text} ring-1 ring-inset ${selectedColor.ring}`}
    >
      {label}
    </span>
  );
};

export default Badge;
