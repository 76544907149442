import { Fragment, useState, useEffect } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { useDispatch, useSelector } from "react-redux";
import { NavLinkWithQuery } from "./shared/NavLinkWithQuery";
import { NavLink, Outlet, useLocation, Navigate } from "react-router-dom";
import { isEqual } from "lodash";
import Tooltip from "./shared/Tooltip";
import Loading from "./shared/Loading";
import Error from "./shared/Error";
import NoAccount from "./shared/NoAccount";
import AppName from "./shared/AppName";
import { useFetchMeasurementsQuery } from "./store/measurementsApiSlice";
import {
  Bars3Icon,
  GlobeAsiaAustraliaIcon,
  XMarkIcon,
  ArrowLeftOnRectangleIcon,
  QuestionMarkCircleIcon,
  CalculatorIcon,
  MagnifyingGlassIcon,
  MegaphoneIcon,
  UserCircleIcon,
  CheckIcon,
} from "@heroicons/react/24/outline";

import { ReactComponent as Trends } from "./assets/trends.svg";
import { ReactComponent as Sensors } from "./assets/sensors.svg";
import { selectSession, selectSessionStatus } from "./store/sessionSlice";
import {
  selectApplicationStatus,
  setActiveDatasets,
  updateActiveObservations,
  fetchContextLayers,
  updateActiveTimeframe,
  updateActiveGrouping,
  selectEnabledModules,
  selectSites,
  setActiveContextLayers,
  selectGroupings,
  setApplicationInitialized,
} from "./store/applicationSlice";
import useBaseParameters from "./hooks/useBaseParameters";
import { fetchObservations } from "./store/portfolioSlice";
import { fetchObservations as fetchSiteObservations } from "./store/siteSlice";
import { usePrevious } from "./hooks/usePrevious";
import ErrorBoundary from "./shared/ErrorBoundary";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function AppShell() {
  let navigation = [
    {
      name: "Map",
      href: "/map",
      icon: GlobeAsiaAustraliaIcon,
      current: true,
    },
    {
      name: "Estimates",
      href: "/estimates",
      icon: CalculatorIcon,
      current: false,
    },
    {
      name: "Response",
      href: "/response",
      icon: CheckIcon,
      current: false,
      beta: true,
    },
    { name: "Trends", href: "/trends", icon: Trends, current: false },
    // { name: "Outliers", href: "/outliers", icon: Outliers, current: false },
    { name: "Sensors", href: "/coverage", icon: Sensors, current: false },
    {
      name: "Reports",
      href: "/reports",
      icon: MagnifyingGlassIcon,
      current: false,
    },
    {
      name: "Media",
      href: "/media",
      icon: MegaphoneIcon,
      current: false,
    },
    {
      name: "Support",
      href:
        process.env.REACT_APP_AIR_AWARE === "true"
          ? "https://docs.google.com/document/d/1vAfHUWy95h0d_hc2S5LezsBjywNUBvCZMP57zW_52iw"
          : "https://docs.google.com/document/d/1qtSFJOsICNsNXaRnrx7iBGkTSwzioJ5mBXstvNA9r5s",
      icon: QuestionMarkCircleIcon,
      current: false,
    },
  ];

  const dispatch = useDispatch();

  const {
    siteId,
    activeDatasetIds,
    activeTimePeriod,
    activeObservationIds,
    activeContextLayerIds,
    activeGrouping,
  } = useBaseParameters();

  const options = {
    start: activeTimePeriod.startDate,
    end: activeTimePeriod.endDate,
  };
  if (siteId) {
    options.siteIds = [siteId];
  }
  if (activeGrouping) {
    options.siteIds = activeGrouping.sites;
  }
  useFetchMeasurementsQuery(options);

  const previousSiteId = usePrevious(siteId);
  const previousActiveDatasetIds = usePrevious(activeDatasetIds);
  const previousActiveTimePeriod = usePrevious(activeTimePeriod);
  const previousActiveObservationIds = usePrevious(activeObservationIds);
  const previousActiveContextLayerIds = usePrevious(activeContextLayerIds);
  const previousActiveGrouping = usePrevious(activeGrouping);

  const [sidebarOpen, setSidebarOpen] = useState(false);
  const location = useLocation();
  const session = useSelector(selectSession);
  const sessionStatus = useSelector(selectSessionStatus);
  const applicationStatus = useSelector(selectApplicationStatus);
  const applicationInitialized = useSelector(
    (state) => state.app.applicationInitialized
  );
  const modules = useSelector(selectEnabledModules);
  const sites = useSelector(selectSites);
  const groupings = useSelector(selectGroupings);

  console.log({ modules });

  // filter navigation based on enabled modules
  if (modules && modules.length) {
    navigation = navigation.filter((item) => {
      return modules.includes(item.name.toLowerCase());
    });
  }

  // eslint-disable-next-line
  // const debouncedFetchObservations = useCallback(
  //   debounce(
  //     (args) => {
  //       console.log("fetching portfolio observations");
  //       dispatch(fetchObservations(args));
  //     },
  //     3000,
  //     { leading: true }
  //   ),
  //   []
  // );

  useEffect(
    () => {
      if (applicationStatus === "succeeded") {
        // deep comparison for :siteId routes
        if (
          !isEqual(previousSiteId, siteId) ||
          !isEqual(previousActiveDatasetIds, activeDatasetIds) ||
          !isEqual(previousActiveTimePeriod, activeTimePeriod)
        ) {
          // sync redux store with our source of truth; url params
          // !! sequence of dispatch matters
          dispatch(setActiveDatasets(activeDatasetIds));
          dispatch(updateActiveTimeframe(activeTimePeriod));
          if (siteId) {
            console.log("fetching site slice observations");
            dispatch(
              fetchSiteObservations({
                siteId,
                activeDatasetIds,
                activeTimePeriod,
              })
            );
            const site = sites.find((site) => site.id === siteId);
            if (site) {
              dispatch(fetchContextLayers(site));
            }
          }
        }
        // deep comparison for portfolio + no need to refetch when
        // prevSiteId === "site123", activeSiteId === null
        if (
          !isEqual(previousActiveDatasetIds, activeDatasetIds) ||
          !isEqual(previousActiveTimePeriod, activeTimePeriod) ||
          !isEqual(previousActiveGrouping, activeGrouping) ||
          !isEqual(previousSiteId, siteId)
        ) {
          if (activeGrouping) {
            dispatch(updateActiveGrouping(groupings[activeGrouping]));
          }
          if (!siteId) {
            dispatch(
              fetchObservations({
                activeTimePeriod,
                activeDatasetIds,
                activeGrouping,
              })
            );
          }
        }
        if (!isEqual(previousActiveObservationIds, activeObservationIds)) {
          dispatch(updateActiveObservations(activeObservationIds));
        }
        if (!isEqual(previousActiveContextLayerIds, activeContextLayerIds)) {
          dispatch(setActiveContextLayers(activeContextLayerIds));
        }
        // after URL state has been reconciled with redux we can safely init the application
        if (!applicationInitialized) {
          dispatch(setApplicationInitialized());
        }
      }
    },
    // eslint-disable-next-line
    [
      applicationStatus,
      dispatch,
      activeDatasetIds,
      activeTimePeriod,
      siteId,
      previousSiteId,
      previousActiveDatasetIds,
      previousActiveTimePeriod,
      previousActiveObservationIds,
      activeObservationIds,
      previousActiveGrouping,
      activeGrouping,
    ]
  );

  if (location.pathname === "/") {
    return <Navigate to="/map" replace />;
  }

  if (sessionStatus === "succeeded" && !session.account) {
    return <NoAccount user={session.email} />;
  }

  if (sessionStatus === "failed" || applicationStatus === "failed") {
    return <Error />;
  }

  if (
    sessionStatus === "loading" ||
    sessionStatus === "idle" ||
    applicationStatus === "loading" ||
    applicationStatus === "idle" ||
    !applicationInitialized
  ) {
    return <Loading />;
  }

  return (
    <>
      <Transition.Root show={sidebarOpen} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-40 md:hidden"
          onClose={setSidebarOpen}
        >
          <Transition.Child
            as={Fragment}
            enter="transition-opacity ease-linear duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-600 bg-opacity-75" />
          </Transition.Child>

          <div className="fixed inset-0 z-40 flex">
            <Transition.Child
              as={Fragment}
              enter="transition ease-in-out duration-300 transform"
              enterFrom="-translate-x-full"
              enterTo="translate-x-0"
              leave="transition ease-in-out duration-300 transform"
              leaveFrom="translate-x-0"
              leaveTo="-translate-x-full"
            >
              <Dialog.Panel className="relative flex w-full max-w-xs flex-1 flex-col bg-gray-800">
                <Transition.Child
                  as={Fragment}
                  enter="ease-in-out duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in-out duration-300"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <div className="absolute right-0 top-0 -mr-12 pt-2">
                    <button
                      type="button"
                      className="ml-1 flex h-10 w-10 items-center justify-center rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                      onClick={() => setSidebarOpen(false)}
                    >
                      <span className="sr-only">Close sidebar</span>
                      <XMarkIcon
                        className="h-6 w-6 text-white"
                        aria-hidden="true"
                      />
                    </button>
                  </div>
                </Transition.Child>
                <div className="h-0 flex-1 overflow-y-auto pb-4 pt-5">
                  <div className="flex flex-col items-center justify-center px-4">
                    <AppName
                      vertical
                      size="sm"
                      color="text-white"
                      withTm={false}
                    />
                  </div>
                  <nav className="mt-5 space-y-1 px-2">
                    {navigation.map((item) => {
                      return (
                        <a
                          key={item.name}
                          href={item.href}
                          target={item.href.includes("http") ? "_blank" : ""}
                          rel="noreferrer"
                          className={classNames(
                            item.current
                              ? "bg-gray-900 text-white"
                              : "text-gray-300 hover:bg-gray-700 hover:text-white",
                            "group flex items-center rounded-md px-2 py-2 text-base font-medium"
                          )}
                        >
                          <item.icon
                            className={classNames(
                              item.current
                                ? "text-gray-300"
                                : "text-gray-400 group-hover:text-gray-300",
                              "mr-4 h-6 w-6 flex-shrink-0"
                            )}
                            aria-hidden="true"
                          />
                          {item.name}
                        </a>
                      );
                    })}
                  </nav>
                </div>
                <div className="flex flex-shrink-0 bg-gray-700 p-4">
                  <NavLink to="/logout" className="group block flex-shrink-0">
                    <div className="flex items-center">
                      <div>
                        <ArrowLeftOnRectangleIcon className="h-6 w-6 text-gray-300" />
                      </div>
                      <div className="ml-3">
                        <p className="text-sm font-medium text-gray-400 group-hover:text-gray-300">
                          Sign out
                        </p>
                      </div>
                    </div>
                  </NavLink>
                </div>
              </Dialog.Panel>
            </Transition.Child>
            <div className="w-14 flex-shrink-0">
              {/* Force sidebar to shrink to fit close icon */}
            </div>
          </div>
        </Dialog>
      </Transition.Root>

      {/* Static sidebar for desktop */}
      <div className="z-50 hidden md:fixed md:inset-y-0 md:flex md:w-20 md:flex-col">
        {/* Sidebar component, swap this element with another sidebar if you like */}
        <div className="flex min-h-0 flex-1 flex-col bg-gray-800">
          <div className="flex flex-1 flex-col overflow-y-auto pb-4">
            <NavLink to="/map">
              <div className="flex h-[68px] flex-col items-center justify-center bg-bsr-blue-500">
                <AppName
                  vertical
                  size="sm"
                  iconColor="white"
                  textColor="text-white"
                  withTm={false}
                />
              </div>
            </NavLink>
            <nav className="mt-8 flex-1 space-y-3">
              {navigation.map((item) => {
                return item.href.includes("http") ? (
                  <a
                    key={item.name}
                    href={item.href}
                    target="_blank"
                    rel="noreferrer"
                    className={
                      "group flex h-16 flex-col items-center justify-center text-sm font-light text-bsr-gray-300 hover:bg-gray-700 hover:text-white"
                    }
                  >
                    <item.icon
                      className={classNames("h-6 w-6 flex-shrink-0 ")}
                      aria-hidden="true"
                    />
                    {item.name}
                  </a>
                ) : (
                  <div className="relative">
                    <NavLinkWithQuery
                      key={item.name}
                      to={item.href}
                      className={({ isActive }) =>
                        classNames(
                          isActive
                            ? "bg-bsr-gray-700 text-white"
                            : "text-bsr-gray-300 hover:bg-gray-700 hover:text-white",
                          "group flex h-16 flex-col items-center justify-center text-sm font-light"
                        )
                      }
                    >
                      <item.icon
                        className={classNames("h-6 w-6 flex-shrink-0 ")}
                        aria-hidden="true"
                      />
                      {item.name}
                    </NavLinkWithQuery>
                    {item.beta && (
                      <span className="absolute bottom-[-12px] left-5 inline-flex items-center rounded-md bg-gray-800 px-2 py-1 text-xs font-medium text-blue-400 ring-1 ring-inset ring-blue-400/30">
                        beta
                      </span>
                    )}
                  </div>
                );
              })}
            </nav>
          </div>
          <div className="mb-2 flex flex-col">
            <Tooltip
              position="top"
              content={session.email || session.displayName}
            >
              <NavLinkWithQuery
                key={"signout-nav-btn"}
                to={"/account"}
                className={({ isActive }) =>
                  classNames(
                    isActive
                      ? "bg-bsr-gray-700 text-white"
                      : "text-bsr-gray-300 hover:bg-gray-700 hover:text-white",
                    "group flex h-16 flex-col items-center justify-center text-sm font-light"
                  )
                }
              >
                <UserCircleIcon
                  className={classNames("h-6 w-6 flex-shrink-0 ")}
                  aria-hidden="true"
                ></UserCircleIcon>
                Account
              </NavLinkWithQuery>
            </Tooltip>
          </div>
        </div>
      </div>
      <div className="flex min-h-screen flex-1 flex-col md:pl-20">
        <div className="sticky top-0 z-10 bg-gray-100 pl-1 pt-1 sm:pl-3 sm:pt-3 md:hidden">
          <button
            type="button"
            className="-ml-0.5 -mt-0.5 inline-flex h-12 w-12 items-center justify-center rounded-md text-gray-500 hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500"
            onClick={() => setSidebarOpen(true)}
          >
            <span className="sr-only">Open sidebar</span>
            <Bars3Icon className="h-7 w-7" aria-hidden="true" />
          </button>
        </div>
        <ErrorBoundary>
          <Outlet />
        </ErrorBoundary>
      </div>
    </>
  );
}
