import React, { Component } from "react";
import { ArrowPathRoundedSquareIcon } from "@heroicons/react/24/outline";

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    // Update state so the next render shows the fallback UI
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    console.error("ErrorBoundary caught an error", error, errorInfo);
  }

  handleRefresh = () => {
    window.location.reload(); // Hard refresh the page
  };

  render() {
    if (this.state.hasError) {
      return (
        <div className="flex h-screen flex-col items-center justify-center bg-gradient-to-r from-gray-100 via-gray-200 to-gray-300 p-6 text-gray-700">
          <div className="max-w-md space-y-4 text-center">
            <h1 className="text-3xl font-extrabold text-gray-800">
              Oops! Something went wrong
            </h1>
            <p className="text-base font-medium text-gray-500">
              The app was unable to load. Please try refreshing the page or
              contact our support team for assistance.
            </p>

            <button
              onClick={this.handleRefresh}
              className="mt-6 inline-flex items-center justify-center rounded-full bg-bsr-blue-500 px-6 py-3 font-semibold text-white shadow-lg transition duration-300 ease-in-out hover:bg-bsr-blue-600 focus:outline-none focus:ring-4 focus:ring-bsr-blue-300"
            >
              <ArrowPathRoundedSquareIcon className="mr-2 h-6 w-6" />
              Refresh Page
            </button>

            <p className="mt-4 text-sm font-medium text-gray-500">
              Still having issues? Reach out to us at{" "}
              <a
                href="mailto:support@airlogic.io"
                className="text-bsr-blue-500 hover:underline"
              >
                support@airlogic.io
              </a>
            </p>
          </div>
        </div>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
