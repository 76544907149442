import { selectOpticalToken } from "./store/applicationSlice";
import { useSelector } from "react-redux";
import { PhotoIcon } from "@heroicons/react/24/outline";
import { classNames, isNullish } from "./utilities";
import "react-popper-tooltip/dist/styles.css";
import { useEffect, useState } from "react";
import { fetchMaxarThumbnail } from "./services/Api.js";

import dayjs from "./dayjs";

const OpticalImagePreview = ({
  previewImage,
  activeImage,
  setTooltipRef,
  getTooltipProps,
  visible,
}) => {
  const image = previewImage ?? activeImage;
  const token = useSelector((state) =>
    selectOpticalToken(state, image?.provider)
  );
  const [thumbnailSrc, setThumbnailSrc] = useState(null);

  useEffect(() => {
    const fetchThumbnail = async () => {
      if (image?.provider !== "maxar") {
        return;
      }

      try {
        const thumbnailSrc = await fetchMaxarThumbnail(
          image.thumbnail_link,
          token
        );
        setThumbnailSrc(thumbnailSrc);
      } catch (error) {
        console.error("Error fetching Maxar thumbnail:", error);
      }
    };

    // Call the async function
    fetchThumbnail();
  }, [image, token]);

  return (
    <div
      {...getTooltipProps({
        className: "tooltip-container",
      })}
      ref={setTooltipRef}
      className={classNames(
        visible ? "visible" : "hidden",
        "z-100 flex flex-col rounded-l-sm border border-gray-400 bg-bsr-gray-050"
      )}
    >
      <div
        style={{ zIndex: -1 }}
        className="absolute -right-[10px] top-[3px] z-10 h-5 w-5 rotate-45 border border-gray-400 bg-white"
      ></div>
      {image?.date && (
        <div className="bg-white leading-relaxed text-bsr-gray-800">
          <div className="flex items-center px-2 py-2">
            <div className="flex-grow border-t border-gray-400"></div>
            <span className="mx-4 flex-shrink text-xs text-gray-400">
              {`Preview ${dayjs(image.date).format("MMM D, YYYY")}  `}
            </span>
            <div className="flex-grow border-t border-gray-400"></div>
          </div>
        </div>
      )}
      <div className="flex w-full justify-center">
        {image && image.img_type !== "task" && image.img_type !== "download" ? (
          <img
            className="m-2 h-[256px] w-[256px] object-scale-down"
            alt="satellite capture"
            src={
              image.provider === "planet"
                ? `${image.thumbnail_link}?api_key=${token}`
                : image.provider === "maxar"
                ? thumbnailSrc
                : image.thumbnail_link
            }
          />
        ) : (
          <div>
            {!image ||
              (image.img_type !== "task" && image.img_type !== "download" && (
                <>
                  <div className="relative flex items-center px-2 py-2">
                    <div className="flex-grow border-t border-gray-400"></div>
                    <span className="mx-4 flex-shrink text-xs text-gray-400">
                      Preview
                    </span>
                    <div className="flex-grow border-t border-gray-400"></div>
                  </div>
                  <div className="m-2 flex h-[256px] w-[256px] flex-col items-center justify-center border-2 border-dashed border-bsr-gray-200 bg-bsr-gray-100 text-bsr-gray-200">
                    <PhotoIcon className="h-24 w-24 text-white" />
                  </div>
                </>
              ))}
          </div>
        )}
      </div>
      <dl className="mt-auto grid w-[275px] grid-cols-2 gap-px rounded-bl-sm bg-bsr-gray-050 p-2">
        <div className="flex flex-wrap items-baseline justify-between bg-white p-1">
          <dt className="text-xs font-medium leading-6 text-gray-500">
            Provider
          </dt>
          <dd className="text-md w-full flex-none font-medium tracking-tight text-gray-900">
            {image?.provider ? image.provider : "--"}
          </dd>
        </div>
        <div className="flex flex-wrap items-baseline justify-between bg-white p-1">
          <dt className="text-xs font-medium leading-6 text-gray-500">
            Resolution
          </dt>
          <dd className="text-md w-full flex-none font-medium tracking-tight text-gray-900">
            {image?.resolution ? `${image.resolution}m` : "--"}
          </dd>
        </div>
        <div className="flex flex-wrap items-baseline justify-between bg-white p-1">
          <dt className="text-xs font-medium leading-6 text-gray-500">
            Cloud Coverage
          </dt>
          <dd className="text-md w-full flex-none font-medium tracking-tight text-gray-900">
            {!isNullish(image?.cloud_cover)
              ? `${(image.cloud_cover * 100).toFixed(0)}%`
              : "--"}
          </dd>
        </div>
        <div className="flex flex-wrap items-baseline justify-between bg-white p-1">
          <dt className="text-xs font-medium leading-6 text-gray-500">
            Site Coverage
          </dt>
          <dd className="text-md w-full flex-none font-medium tracking-tight text-gray-900">
            {image?.img_cover ? `${image.img_cover}%` : "--"}
          </dd>
        </div>
      </dl>
    </div>
  );
};

export default OpticalImagePreview;
