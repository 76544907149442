import { Switch } from "antd";
import { toggleLayerVisibility } from "./utilities";

const LEGEND_COLORS = {
  priorDetection: [39, 144, 245, 200],
  currentDetection: [218, 18, 125, 200],
};
export default function MeasurementMapOperationsPopup({
  overlay,
  plumeLayerId,
}) {
  return (
    <>
      <div className="flex justify-center rounded-t-md bg-bsr-gray-300 px-2 text-white">
        Legend
      </div>
      <div className="py-1">
        {/* Map Legend */}
        <div
          key="map-legend"
          className="my-1 grid grid-cols-1 gap-2 bg-white p-1 shadow-lg"
        >
          {/* current detection */}
          <div key="current-detection-legend" className="flex bg-white">
            <div
              className="my-auto h-3 w-3 rounded-lg"
              style={{
                backgroundColor: `rgba(${LEGEND_COLORS.currentDetection.join(
                  ","
                )})`,
              }}
            ></div>
            <div className="px-2">
              <div className="text-sm font-light">Current Detection</div>
            </div>
          </div>

          {/* prior detection */}
          <div key="prior-detection-legend" className="flex bg-white">
            <div
              className="my-auto h-3 w-3 rounded-lg"
              style={{
                backgroundColor: `rgba(${LEGEND_COLORS.priorDetection.join(
                  ","
                )})`,
              }}
            ></div>
            <div className="px-2">
              <div className="text-sm font-light">Prior Detection</div>
            </div>
          </div>
        </div>
        {/* end Map Legend */}

        {/* Toggle Plume Layer */}
        <div
          key="toggle-plume-layer"
          className="my-1 flex bg-white p-1 shadow-lg"
        >
          <div className="mr-2">
            <Switch
              size="small"
              defaultChecked={
                overlay.props.layers.find((layer) => layer?.id === plumeLayerId)
                  ?.props?.visible
              }
              onChange={() => {
                toggleLayerVisibility(overlay, plumeLayerId);
              }}
            />
          </div>
          <div className="my-auto text-sm font-light">Toggle Plume Layer</div>
        </div>
      </div>
    </>
  );
}
