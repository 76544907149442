import { configureStore } from "@reduxjs/toolkit";
import { measurementsApi } from "./measurementsApiSlice"; // import the API slice
import { combineReducers } from "redux";
import { auth, serializeFirebaseUser } from "../shared/firebase";
import sessionReducer, {
  sessionAdded,
  sessionRemoved,
} from "../store/sessionSlice";

import applicationReducer from "./applicationSlice";
import dataCoverageReducer from "./dataCoverageSlice";
import portfolioReducer from "./portfolioSlice";
import siteReducer from "./siteSlice";
import responseSlice from "./responseSlice";

const appReducer = combineReducers({
  app: applicationReducer,
  session: sessionReducer,
  portfolio: portfolioReducer,
  site: siteReducer,
  dataCoverage: dataCoverageReducer,
  response: responseSlice,
  [measurementsApi.reducerPath]: measurementsApi.reducer,
});

// return inital state for entire state tree when user logs out
const reducer = (state, action) => {
  if (action.type === "session/signOut/fulfilled") {
    return appReducer(undefined, action);
  }
  return appReducer(state, action);
};

export const store = configureStore({
  reducer: reducer,
  // Add the api middleware to enable caching, automatic refetching, etc.
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(measurementsApi.middleware),
});

// Called by firebase when the page first loads. Firebase loads user info from a cookie perhaps
// we watch for that change and update the session in our store
auth.onAuthStateChanged(
  async (firebaseUser) => {
    if (!firebaseUser) {
      store.dispatch(sessionRemoved());
      return;
    }
    console.time("token_fetch");
    // After a user signs up. We add them to an account using slack. We email
    //   the user and tell them to refresh airlogic. We need to force a refresh
    // of the user's token so that they can see their new custom claims.
    const token = await firebaseUser.getIdTokenResult(true);
    console.timeEnd("token_fetch");
    console.log({ token });
    firebaseUser["account"] = token.claims?.account_id;
    firebaseUser["token"] = token.token;
    // the firebase response is not pure JSON and cannot be serialzed by react. So we extract the fields we need here:
    const session = serializeFirebaseUser(firebaseUser);
    store.dispatch(sessionAdded({ ...session }));
    window.gtag("event", "user_login", {
      event_category: "login",
      event_label: "login",
      value: `${session.account} | ${session.email}`,
    });
  },
  (error) => {
    console.error(error);
    // TODO
  }
);
