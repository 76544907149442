import {
  createSlice,
  createAsyncThunk,
  createSelector,
} from "@reduxjs/toolkit";
import { db } from "../shared/firebase";
import dayjs from "../dayjs";
import Site, { siteConverter } from "../SiteData";
import uniqid from "uniqid";
import api, {
  getUserSettingsDoc,
  createUserSettingsDoc,
} from "../services/Api";
import { translateDatasetsByCadence, buildObservationId } from "../utilities";
import { COLORS, DEFAULT_USER_SETTINGS, timeFrames } from "../constants";
import timestampConverter from "../converters/timestampConverter";
import firebase from "firebase/compat/app";
import { fetchAccountDatasets, fetchAccountTags } from "./responseSlice";

const initialState = {
  status: "idle",
  error: null,
  // UI
  activeTopic: null,
  activeGrouping: null,
  selectedMetrics: [],
  selectedMetric: null,
  activeObservations: [],
  activeSensor: null,
  activeTimePeriod: timeFrames[0],
  notifications: [],
  // CONTEXT
  contextLayers: [],
  opticalLayers: [],
  activeContextLayers: [],
  activeOpticalLayer: null,
  // SITES
  sites: [],
  // USER SETTINGS
  settings: DEFAULT_USER_SETTINGS,
  sensors: [],
  // DATASETS
  activeDatasets: [],
  datasets: [],
  // CONFIGURATION
  configuration: {},
  // MAP
  mapLayerOpacity: {},
  hideEmptyDatasets: true,
  showPlumeImagery: true,
  showRosettaValues: true,
  opticalTokens: null,
  applicationInitialized: false,
};

function fetchSites(accountId) {
  return db
    .collection(`accounts/${accountId}/sites`)
    .where("isDeleted", "==", false)
    .withConverter(siteConverter)
    .get()
    .then((snapshot) => {
      return snapshot.docs.map((doc) => ({ ...doc.data() }));
    });
}

export const fetchContextLayers = createAsyncThunk(
  "app/fetchContextLayers",
  async (site) => {
    try {
      const layers = await api.fetchContextLayers(site.id);
      // return virtual layer here with site bounds
      const virtualSiteBoundsLayer = {
        id: site.id,
        title: "Bluesky Site Boundary",
        last_updated: "",
        type: "geojson",
        data: site.geometry,
        color: [218, 18, 125],
        category: "Bluesky Site Boundary",
      };
      return [...layers, virtualSiteBoundsLayer];
    } catch (err) {
      console.log("something went wrong while fetching context layers");
      console.error(err);
      throw new Error(err);
    }
  }
);

export const fetchOpticalTokens = createAsyncThunk(
  "app/fetchOpticalTokens",
  api.fetchOpticalTokens
);

export const fetchOpticalLayers = createAsyncThunk(
  "app/fetchOpticalLayers",
  async ({ siteId, start, end }) => {
    try {
      const payload = await api.fetchOptical({
        siteId,
        start,
        end,
      });
      console.log({ payload });
      return payload;
    } catch (err) {
      console.log(
        `something went wrong while fetching recent optical images for ${siteId}`
      );
      console.error(err);
      return err;
    }
  }
);

export const fetchAccountResources = createAsyncThunk(
  "app/fetchAccountResources",
  async (_, { getState, dispatch }) => {
    try {
      const accountId = getState().session.data.account;
      dispatch(fetchAccountTags());
      dispatch(fetchAccountDatasets());
      const [sites, settings, sensors, datasets, configuration, tokens] =
        await Promise.all([
          fetchSites(accountId),
          getUserSettingsDoc(),
          api.fetchAccountSensors(accountId),
          api.fetchAccountDatasets(accountId),
          api.fetchAccountConfiguration(accountId),
          api.fetchOpticalTokens(),
        ]);
      console.log({
        sites,
        settings,
        sensors,
        datasets,
        configuration,
        tokens,
      });
      return {
        sites,
        settings,
        sensors,
        datasets,
        configuration,
        tokens,
      };
    } catch (err) {
      console.log(
        "something went wrong during account resource initialization"
      );
      console.error(err);
      return err;
    }
  }
);

// NOTIFICATIONS ACTIONS
export const createNotification = (notification) => (dispatch) => {
  // add id and show property to notification. show is used by Transitions in Notification component to display the notification
  const n = {
    id: uniqid(),
    show: true,
    ...notification,
  };

  const timeToLive = notification.timeToLive ? notification.timeToLive : 4000;

  dispatch(notificationAdded(n));
  return new Promise((resolve) => {
    setTimeout(() => {
      dispatch(notificationRemoved(n.id));
      resolve();
    }, timeToLive);
  });
};

// SITES ACTIONS
export const createSite = createAsyncThunk(
  "app/createSite",
  async ({
    id,
    tags,
    name,
    geometry,
    createdBy,
    account,
    createdAt,
    isDeleted,
    totalSqKm,
    totalPoints,
  }) => {
    try {
      console.log(
        `attempting to save new site ${id} to accounts/${account}/sites...`
      );
      const siteRef = db
        .collection(`accounts/${account}/sites`)
        .doc(id)
        .withConverter(siteConverter);

      siteRef.set(
        new Site({
          id,
          name,
          tags,
          geometry,
          account,
          created_by: createdBy,
          isDeleted: isDeleted,
          created_at: createdAt,
          total_sq_km: totalSqKm,
          total_points: totalPoints,
        })
      );

      console.log("saved successfully.");
      return;
    } catch (error) {
      console.log(error);
      throw error;
    }
  }
);

export const changeSiteName = createAsyncThunk(
  "app/changeSiteName",
  async ({ accountId, siteName, siteId }) => {
    try {
      await db.collection(`accounts/${accountId}/sites`).doc(siteId).update({
        name: siteName,
      });
    } catch (error) {
      console.log(error);
      throw error;
    }
    return {
      accountId,
      siteName,
      siteId,
    };
  }
);

export const deleteSite = createAsyncThunk(
  "app/deleteSite",
  async ({ accountId, isDeleted, siteId }) => {
    try {
      await db.collection(`accounts/${accountId}/sites`).doc(siteId).update({
        isDeleted: isDeleted,
      });
    } catch (error) {
      console.log(error);
      throw error;
    }
    return {
      accountId,
      isDeleted,
      siteId,
    };
  }
);

export const setSiteId = createAsyncThunk(
  "app/setSiteId",
  async ({ siteId, accountId, alternateSiteId }) => {
    try {
      await db.collection(`accounts/${accountId}/sites`).doc(siteId).update({
        alternateSiteId: alternateSiteId,
      });
    } catch (error) {
      console.log(error);
      throw error;
    }
    return { siteId, alternateSiteId };
  }
);

export const updateSettingsDocument = createAsyncThunk(
  "app/updateSettingsDoc",
  async (
    { property, value, record },
    { getState, dispatch, rejectWithValue }
  ) => {
    const {
      session: {
        data: { account, uid, email },
      },
    } = getState();

    try {
      if (record.mock) {
        await createUserSettingsDoc(account, uid, email);
      }

      // Perform the update operation
      await db
        .collection(`accounts/${account}/user_settings`)
        .doc(uid)
        .update({
          [property]: value,
          modified_ts: firebase.firestore.FieldValue.serverTimestamp(),
        });

      // Fetch the updated document
      const updatedDoc = await db
        .collection(`accounts/${account}/user_settings`)
        .doc(uid)
        .withConverter(timestampConverter)
        .get();

      if (!updatedDoc.exists) {
        throw new Error("Document does not exist");
      }

      return updatedDoc.data();
    } catch (error) {
      console.log(error);
      dispatch(
        createNotification({
          title: "Action failed",
          description: "Failed to save changes",
          type: "warning",
        })
      );
      // we reject with original pre-modified record so that our thunk rejected
      // handler can undo the optimistic update e.g. return original state
      return rejectWithValue(record);
    }
  }
);

export const applicationSlice = createSlice({
  name: "app",
  initialState,
  reducers: {
    updateSelectedMetrics: (state, action) => {
      if (
        state.selectedMetrics.find((metric) => metric.id === action.payload.id)
      ) {
        state.selectedMetrics = state.selectedMetrics.filter(
          (metric) => metric.id !== action.payload.id
        );
      } else {
        state.selectedMetrics = [...state.selectedMetrics, action.payload];
      }
    },
    replaceSelectedMetric: (state, action) => {
      state.selectedMetric = action.payload;
    },
    updateActiveDatasets: (state, action) => {
      if (state.activeDatasets.find((d) => d.id === action.payload.id)) {
        state.activeDatasets = state.activeDatasets.filter((d) => {
          return d.id !== action.payload.id;
        });
      } else {
        const newDatasets = [...state.activeDatasets, action.payload];
        state.activeDatasets = newDatasets;
      }
    },
    setActiveDatasets: (state, action) => {
      const datasets = action.payload.map((id) =>
        state.datasets.find((d) => d.id === id)
      );

      state.activeDatasets = datasets;
      state.selectedMetrics = [datasets[0]];
      state.selectedMetric = datasets[0];
    },
    clearActiveSensor: (state) => {
      state.activeSensor = null;
    },
    updateActiveTimeframe: (state, action) => {
      const previousCadence = state.activeTimePeriod?.cadence;
      state.activeTimePeriod = action.payload;
      if (previousCadence) {
        const activeDatasetsForCurrentCadence = translateDatasetsByCadence(
          previousCadence,
          action.payload.cadence,
          state.activeDatasets,
          state.datasets
        );
        state.activeDatasets = activeDatasetsForCurrentCadence;

        // clear active observations when user navigates away from time period
        // of active observation
        state.activeObservations = [];
      }
    },
    incrementTimeBounds: (state, action) => {
      const cadence = action.payload;
      let startDate;
      let endDate;
      if (cadence === "daily") {
        startDate = dayjs(state.activeTimePeriod.startDate).add(30, "day");
        endDate = dayjs(state.activeTimePeriod.endDate).add(30, "day");
      } else if (cadence === "monthly") {
        startDate = dayjs(state.activeTimePeriod.startDate).add(1, "year");
        endDate = dayjs(state.activeTimePeriod.endDate).add(1, "year");
      } else if (cadence === "yearly") {
        startDate = dayjs(state.activeTimePeriod.startDate).add(2, "year");
        endDate = dayjs(state.activeTimePeriod.endDate).add(2, "year");
      }
      state.activeTimePeriod = {
        startDate: startDate.toISOString(),
        endDate: endDate.toISOString(),
        cadence,
        name: `${startDate.format("MMM D, YYYY")} - ${endDate
          .subtract(1, "second")
          .format("MMM D, YYYY")}`,
      };
      // clear active observations when user navigates away from time period
      // of active observation
      state.activeObservations = [];
    },
    decrementTimeBounds: (state, action) => {
      const cadence = action.payload;
      let startDate;
      let endDate;
      if (cadence === "daily") {
        startDate = dayjs(state.activeTimePeriod.startDate).subtract(30, "day");
        endDate = dayjs(state.activeTimePeriod.endDate).subtract(30, "day");
      } else if (cadence === "monthly") {
        startDate = dayjs(state.activeTimePeriod.startDate).subtract(1, "year");
        endDate = dayjs(state.activeTimePeriod.endDate).subtract(1, "year");
      } else if (cadence === "yearly") {
        startDate = dayjs(state.activeTimePeriod.startDate).subtract(2, "year");
        endDate = dayjs(state.activeTimePeriod.endDate).subtract(2, "year");
      }
      state.activeTimePeriod = {
        startDate: startDate.toISOString(),
        endDate: endDate.toISOString(),
        cadence,
        name: `${startDate.format("MMM D, YYYY")} - ${endDate
          .subtract(1, "second")
          .format("MMM D, YYYY")}`,
      };
      // clear active observations when user navigates away from time period
      // of active observation
      state.activeObservations = [];
    },
    updateActiveObservations: (state, action) => {
      state.activeObservations = action.payload;
    },
    clearActiveObservations: (state) => {
      state.activeObservations = [];
    },
    selectActiveSensor: (state, action) => {
      state.activeSensor = action.payload;
    },
    notificationAdded: (state, action) => {
      state.notifications.push(action.payload);
    },
    notificationRemoved: (state, action) => {
      state.notifications.find(
        (element) => element.id === action.payload
      ).show = false;
    },
    updateMapOpacity: (state, action) => {
      state.mapLayerOpacity[action.payload.id] = action.payload.value;
    },
    setActiveContextLayers: (state, action) => {
      state.activeContextLayers = action.payload;
    },
    updateActiveOpticalLayer: (state, action) => {
      state.activeOpticalLayer = action.payload;
    },
    updateActiveGrouping: (state, action) => {
      state.activeGrouping = action.payload;
    },
    updateHideEmptyDatasets: (state, action) => {
      state.hideEmptyDatasets = action.payload;
    },
    toggleShowPlumeImagery: (state) => {
      state.showPlumeImagery = !state.showPlumeImagery;
    },
    toggleShowRosettaValues: (state) => {
      state.showRosettaValues = !state.showRosettaValues;
    },
    setSettings: (state, action) => {
      state.settings = action.payload;
    },
    setApplicationInitialized: (state) => {
      state.applicationInitialized = true;
    },
  },
  extraReducers: {
    [fetchAccountResources.fulfilled]: (state, action) => {
      const { sites, settings, sensors, datasets, configuration, tokens } =
        action.payload;

      state.sites = sites;
      state.settings = settings;
      state.sensors = sensors;
      state.datasets = datasets;
      state.configuration = configuration;
      state.opticalTokens = tokens;

      try {
        state.activeDatasets = configuration.defaultDatasets
          .map((matcher) => {
            return datasets.find((d) => {
              const regex = new RegExp(matcher);
              return (
                d.frequency === state.activeTimePeriod.cadence &&
                regex.test(d.id)
              );
            });
          })
          // remove undefined - this can happen if the default dataset is not available for the active cadence
          .filter((d) => d !== undefined);
      } catch (err) {
        console.log(
          "failed to initialize active datasets from configuration boot file"
        );
        console.log(err);
      }

      state.status = "succeeded";
    },
    [fetchAccountResources.pending]: (state) => {
      state.status = "loading";
    },
    [changeSiteName.fulfilled]: (state, action) => {
      const site = state.sites.find(
        (site) => site.id === action.payload.siteId
      );
      site.name = action.payload.siteName;
    },
    [setSiteId.fulfilled]: (state, action) => {
      const { siteId, alternateSiteId } = action.payload;
      const site = state.sites.find((site) => site.id === siteId);
      site.alternateSiteId = alternateSiteId;
    },
    [deleteSite.fulfilled]: (state, action) => {
      const index = state.sites.findIndex(
        (site) => site.id === action.payload.siteId
      );
      if (index !== -1) state.sites.splice(index, 1);
    },
    [fetchContextLayers.fulfilled]: (state, action) => {
      state.contextLayers = action.payload;
    },
    [fetchOpticalLayers.fulfilled]: (state, action) => {
      state.opticalLayers = action.payload;
    },
    [fetchOpticalTokens.fulfilled]: (state, action) => {
      state.opticalTokens = action.payload;
    },
    [updateSettingsDocument.fulfilled]: (state, action) => {
      state.settings = action.payload;
    },
    [updateSettingsDocument.rejected]: (state, action) => {
      state.settings = action.payload;
    },
    [updateSettingsDocument.pending]: (state, action) => {
      state.settings = {
        ...state.settings,
        updating: true,
        [action.meta.arg.property]: action.meta.arg.value,
      };
    },
  },
});

export const {
  updateMapOpacity,
  updateActiveTopic,
  updateActiveTimeframe,
  updateActiveDatasets,
  setActiveDatasets,
  incrementTimeBounds,
  decrementTimeBounds,
  updateSelectedMetrics,
  updateActiveObservations,
  clearActiveObservations,
  replaceSelectedMetric,
  notificationAdded,
  notificationRemoved,
  selectActiveSensor,
  clearActiveSensor,
  resetState,
  updateActiveSite,
  setSearchParameters,
  setActiveContextLayers,
  updateActiveGrouping,
  updateHideEmptyDatasets,
  toggleShowPlumeImagery,
  toggleShowRosettaValues,
  updateActiveOpticalLayer,
  setSettings,
  setApplicationInitialized,
} = applicationSlice.actions;

export const selectApplicationStatus = (state) => state.app.status;
export const selectActiveTimePeriod = (state) => state.app.activeTimePeriod;
export const selectSelectedDatasets = (state) => state.app.selectedMetrics;
export const selectSelectedMetric = createSelector(
  (state) => state.app.selectedMetric,
  (selectedMetric) => {
    return {
      color: COLORS[0],
      ...selectedMetric,
    };
  }
);
export const selectSearchParameters = (state) => state.app.searchParameters;

export const selectNotifications = (state) => state.app.notifications;
export const selectDatasetsStatus = (state) => state.app.status;
export const selectDatasets = (state) => state.app.datasets;
export const selectDatasetsByActiveCadence = (state) => {
  // if for some wild reason a non-daily dataset had "daily" in the id this
  // kind of implementation might break. true for any of the cadences.
  // skip datasets that do not have a category. these are often used for reference or for dataset_coverage
  return state.app.datasets.filter((d) => {
    return d.id.includes(state.app.activeTimePeriod.cadence) && d.category;
  });
};

export const selectActiveDatasets = createSelector(
  (state) => state.app.activeDatasets,
  (activeDatasets) => {
    return activeDatasets.map((d, idx) => ({
      ...d,
      color: COLORS[idx],
    }));
  }
);

export const selectActiveDatasetIds = (state) =>
  state.app.activeDatasets.map((d) => d.id);

export const selectSensors = (state) => state.app.sensors;

export const selectActiveObservations = createSelector(
  (state) => state.site.data,
  (state) => state.app.datasets,
  (state) => state.app.activeObservations,
  (data, datasets, activeObservations) => {
    if (data && data.length && activeObservations.length) {
      const observations = data
        .filter((o) => {
          const observationId = buildObservationId(
            o.dataset_id,
            o.datetime_str
          );
          return activeObservations.includes(observationId);
        })
        .map((o) => {
          const observationId = buildObservationId(
            o.dataset_id,
            o.datetime_str
          );
          const dataset = datasets.find(
            (dataset) => o.dataset_id === dataset.id
          );
          return { ...o, dataset, id: observationId };
        });
      return observations;
    } else {
      return [];
    }
  }
);

export const selectActiveObservationIds = (state) =>
  state.app.activeObservations;

export const selectGroupings = (state) => {
  const favoriteSites = state.app.settings.favorite_site_ids;
  if (favoriteSites?.length) {
    return {
      ...state.app.configuration?.groupings,
      favorite_sites: {
        id: "favorite_sites",
        name: "Favorite Sites",
        description: "Sites that you have starred appear here",
        sites: favoriteSites,
      },
    };
  }
  return state.app.configuration?.groupings;
};
export const selectEnabledModules = (state) =>
  state.app.configuration?.enabledModules;

export const selectDatasetCategories = (state) =>
  state.app.configuration?.["datasetCategories"];

export const selectSiteById = (state, siteId) => {
  if (!siteId) return null;
  return state.app.sites.find((site) => site.id === siteId);
};
export const selectSites = (state) => {
  if (state.app.activeGrouping) {
    const filteredSites = state.app.sites.filter((s) =>
      state.app.activeGrouping.sites.includes(s.id)
    );
    return filteredSites;
  }
  return state.app.sites;
};
export const selectSitesCount = (state) => {
  return state.app.sites?.length;
};
export const selectSitesGeometries = createSelector(
  (state) => state.app.sites,
  (sites) => {
    const geometries = sites.map((site) => {
      const { geometry, ...rest } = site;
      // enrich sites boundary GEOJSON with site metadata
      return { geometry, properties: rest };
    });
    return geometries;
  }
);

export const selectEstimatesPresets = (state) =>
  state.app.configuration?.estimatesPresets;

export const selectActiveObservation = (state, observationId) =>
  state.app.activeObservations.find((o) => o.id === observationId);

export const selectContextLayers = (state) => state.app.contextLayers;
export const selectActiveContextLayers = createSelector(
  (state) =>
    state.app.contextLayers.filter((l) =>
      state.app.activeContextLayers.includes(l.id)
    ),
  (activeContextLayers) => activeContextLayers
);

export const selectActiveContextLayerIds = (state) =>
  state.app.activeContextLayers;

export const selectActiveGrouping = (state) => state.app.activeGrouping;
export const selectHideEmptyDatasets = (state) => state.app.hideEmptyDatasets;
export const selectShowPlumeImagery = (state) => state.app.showPlumeImagery;
export const selectShowRosettaValues = (state) => state.app.showRosettaValues;
export const selectOpticalLayers = (state) => state.app.opticalLayers;
export const selectActiveOpticalLayer = (state) => state.app.activeOpticalLayer;
export const selectOpticalToken = (state, provider) =>
  state.app.opticalTokens[provider];

export const selectUserSettings = (state) => state.app.settings;

export default applicationSlice.reducer;
