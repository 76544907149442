import React from "react";
import { isEqual, isEmpty } from "lodash";
import dayjs from "./dayjs";
import { GoogleMapsOverlay as DeckOverlay } from "@deck.gl/google-maps";
import {
  GeoJsonLayer,
  BitmapLayer,
  TextLayer,
  LineLayer,
} from "@deck.gl/layers";
import centroid from "@turf/centroid";
import bbox from "@turf/bbox";

import { auth } from "./shared/firebase";
import api from "./services/Api";
import {
  computeColor,
  hexToRgb,
  computeStoneColor,
  getHtml,
  formatData,
  classNames,
  readingsToGeojson,
  createPoints,
  updateUrlParameter,
  isTokenExpired,
} from "./utilities";
import HoveredMapInfo from "./shared/HoveredMapInfo";
import LoadingOverlay from "./shared/LoadingOverlay";
import {
  Square3Stack3DIcon,
  ArrowsPointingInIcon,
  ViewfinderCircleIcon,
} from "@heroicons/react/24/solid";
import constructBaseGeojsonLayer from "./layers/geojson";
import constructBaseTileLayer from "./layers/tms";
import constructBaseH3Layer from "./layers/hex";
import v2hovers from "./hovers/map";
import { feature, featureCollection } from "@turf/helpers";
import ContextLayersPopup from "./ContextLayersPopup";
import OpticalLayersPopup from "./OpticalLayersPopup";
import streetPng from "./assets/street.png";
import satellitePng from "./assets/satellite.png";
import terrainPng from "./assets/terrain.png";
import { Popover } from "@headlessui/react";

class SiteMap extends React.Component {
  constructor(props) {
    super(props);
    this.mapRef = React.createRef();
    this.overlay = new DeckOverlay({
      layers: [],
      getTooltip: ({ object }) => {
        // the hover_disable flag is an escape hatch for when you want a geojson feature
        // to have no hover capabilities when added to the geojson as a feature
        if (object && object.properties?.["hover_disabled"]) return;
        if (object && object.properties && object.properties.layerTypeV2) {
          console.log("activating v2 tooltip");
          const hoverFn = v2hovers[object.properties.layerTypeV2];
          if (hoverFn) {
            return {
              html: hoverFn({
                ...object.properties,
                geometry: object.geometry,
              }),
              style: {
                backgroundColor: "#616E7C",
                whiteSpace: "nowrap",
                fontSize: "1.5em",
                padding: "5px",
                color: "white",
              },
            };
          }
        } else if (object && object.properties) {
          console.log("activating v1 tooltip");
          return {
            html: `<div>${getHtml({
              ...object.properties,
              geometry: object.geometry,
            })}</div>`,
            style: {
              backgroundColor: "#616E7C",
              whiteSpace: "nowrap",
              fontSize: "1.5em",
              padding: "5px",
              color: "white",
            },
          };
        }
      },
    });
    this.state = {
      loading: false,
      hoveredInfo: null,
      latLng: null,
    };
  }

  async initMap() {
    const { Map } = await window.google.maps.importLibrary("maps");

    const { x, y, z } = this.props;
    let centerLatLng = { lat: x || 40.015, lng: y || -105.2705 };

    if (this.mapRef.current) {
      this.map = new Map(this.mapRef.current, {
        center: centerLatLng,
        zoom: z || 3,
        tilt: 0,
        mapTypeId: "satellite",
        mapTypeControlOptions: {
          mapTypeIds: ["roadmap", "terrain", "satellite", "hybrid"],
          position: window.google.maps.ControlPosition.TOP_RIGHT,
        },
        zoomControlOptions: {
          position: window.google.maps.ControlPosition.TOP_RIGHT,
        },
        scaleControl: true,
        fullscreenControl: false,
        streetViewControl: false,
        rotateControl: false,
        mapTypeControl: false,
      });

      if (!x && !y && !z) {
        this.zoomToActiveSite(this.props.site);
      }

      this.map.addListener("idle", () => {
        const latLng = this.map.getCenter();
        const zoom = this.map.getZoom();

        this.props.searchParameters.set("y", latLng.lng());
        this.props.searchParameters.set("x", latLng.lat());
        this.props.searchParameters.set("z", zoom);

        this.props.setSearchParameters(this.props.searchParameters, {
          replace: true,
        });
      });
    }
  }

  async componentDidMount() {
    await this.initMap();

    // const siteLayer = this.constructSiteLayer(this.props.site?.geometry);

    // ONE TIME SYNC GMAPS INSTANCE WITH DECK
    this.overlay.setMap(this.map);
    // this.overlay.setProps({
    //   layers: [siteLayer],
    // });

    this.renderLayers();
  }

  findBoundsOfSite(siteGeometry) {
    console.log({ siteGeometry });
    try {
      let latLngs = [];
      if (siteGeometry.type === "MultiPolygon") {
        const [minX, minY, maxX, maxY] = bbox(siteGeometry); // Example: [0, 0, 10, 10]

        // Create LatLng instances
        const southwest = new window.google.maps.LatLng(minY, minX);
        const northeast = new window.google.maps.LatLng(maxY, maxX);

        // Create LatLngBounds instance
        const bounds = new window.google.maps.LatLngBounds(
          southwest,
          northeast
        );
        return bounds;
      } else if (siteGeometry.type === "GeometryCollection") {
        siteGeometry.geometries.forEach((geometry) => {
          geometry.coordinates[0].forEach((coord) => {
            latLngs.push(new window.google.maps.LatLng(coord[1], coord[0]));
          });
        });
      } else {
        siteGeometry.coordinates[0].forEach((coord) => {
          latLngs.push(new window.google.maps.LatLng(coord[1], coord[0]));
        });
      }
      const bounds = new window.google.maps.LatLngBounds();
      latLngs.forEach((latLng) => bounds.extend(latLng));
      return bounds;
    } catch (err) {
      console.error(
        "unable to extract location information from provided site geometry"
      );
      return null;
    }
  }

  zoomToActiveSite(activeSite) {
    console.log({ activeSite });
    const bounds = this.findBoundsOfSite(activeSite.geometry);
    if (bounds) {
      this.map.fitBounds(bounds, { bottom: 350 });
    }
  }

  // updateLatLngLayer(latLng) {
  //   const layers = this.overlay.props.layers.map((layer) => {
  //     if (layer.id === "LatLngIcon" || layer.id === "LatLngText") {
  //       const updated = layer.clone({
  //         data: [{ coordinates: [latLng.lng(), latLng.lat()] }],
  //       });
  //       return updated;
  //     } else {
  //       return layer;
  //     }
  //   });
  //   this.overlay.setProps({ layers });
  // }

  // constructLatLngLayer() {
  //   const latLng = this.map.getCenter();
  //   console.log([latLng.lat(), latLng.lng()]);
  //   return new IconLayer({
  //     id: "LatLngIcon",
  //     data: [{ coordinates: [latLng.lng(), latLng.lat()] }],
  //     getColor: (d) => [255, 255, 255, 150],
  //     getIcon: (d) => "crosshair",
  //     getPosition: (d) => d.coordinates,
  //     getSize: 100,
  //     iconAtlas: CrosshairPng,
  //     iconMapping: {
  //       crosshair: {
  //         x: 0,
  //         y: 0,
  //         width: 500,
  //         height: 500,
  //         mask: false,
  //       },
  //     },
  //   });
  // }

  async componentDidUpdate(prevProps) {
    if (prevProps.paramsSiteId !== this.props.paramsSiteId) {
      // const siteLayer = this.constructSiteLayer(this.props.site?.geometry);
      this.zoomToActiveSite(this.props.site);
      // this.overlay.setProps({
      //   layers: [siteLayer],
      // });
    }

    if (!isEqual(prevProps.showLatLng, this.props.showLatLng)) {
      if (this.props.showLatLng) {
        this.map.setOptions({ draggableCursor: "crosshair" });
        this.mouseClickListener = this.map.addListener("click", (e) => {
          const coordinates = `${e.latLng.lat().toFixed(6)}, ${e.latLng
            .lng()
            .toFixed(6)}`;
          navigator.clipboard.writeText(coordinates);
          this.props.createNotification({
            title: "Coordinates copied to clipboard!",
            description: coordinates,
          });
        });
        this.mouseMoveListener = this.map.addListener("mousemove", (e) => {
          this.setState({ latLng: e.latLng });
        });
      } else {
        window.google.maps.event.removeListener(this.mouseClickListener);
        window.google.maps.event.removeListener(this.mouseMoveListener);
        this.map.setOptions({ draggableCursor: "" });
      }
    }

    // redraw layers as map opacity slider changes
    if (!isEqual(prevProps.mapLayerOpacity, this.props.mapLayerOpacity)) {
      const layers = this.overlay.props.layers.map((layer) => {
        const opacityKey = Object.keys(this.props.mapLayerOpacity).find((key) =>
          layer.id.includes(key)
        );
        if (opacityKey) {
          const copy = layer.clone({
            opacity: this.props.mapLayerOpacity[opacityKey],
          });
          return copy;
        } else {
          return layer;
        }
      });
      this.overlay.setProps({
        layers: layers.filter(Boolean),
      });
    }

    if (!isEqual(prevProps.showPlumeImagery, this.props.showPlumeImagery)) {
      const layers = this.overlay.props.layers.map((layer) => {
        if (layer.props.layerType === "PlumeLayer") {
          const copy = layer.clone({
            visible: this.props.showPlumeImagery,
          });
          return copy;
        } else {
          return layer;
        }
      });

      this.overlay.setProps({
        layers,
      });
    }

    if (
      !isEqual(prevProps.activeObservations, this.props.activeObservations) ||
      !isEqual(prevProps.activeContextLayers, this.props.activeContextLayers) ||
      !isEqual(prevProps.activeOpticalLayer, this.props.activeOpticalLayer) ||
      !isEqual(prevProps.showLatLng, this.props.showLatLng)
    ) {
      this.renderLayers();
    }
  }

  async renderLayers() {
    let layers = [];

    const layerOrder = {
      optical: 1,
      "optical-preview": 1,
      GoogleEarthEngineSatelliteLayer: 2,
      ParticleLayer: 3,
      WindroseLayer: 4,
      SemLayer: 5,
      SemHeatMapLayer: 5,
      semFluxHeatMapLayer: 5,
    };

    const orderLayersByZIndex = (a, b) => {
      if (a.dataset?.details?.type === b.dataset?.details?.type) {
        return (a.dataset?.weight || 0) - (b.dataset?.weight || 0);
      }

      return (
        (layerOrder[a.dataset?.details?.type] || 5) -
        (layerOrder[b.dataset?.details?.type] || 5)
      );
    };

    this.setState({ loading: true });

    if (this.props.activeOpticalLayer) {
      layers.push(
        this.constructOpticalBasemapLayer({
          id: this.props.activeOpticalLayer.id,
          xyz_link: this.props.activeOpticalLayer.xyz_link,
          provider: this.props.activeOpticalLayer.provider,
        })
      );
    }

    this.props.activeObservations
      .sort(orderLayersByZIndex)
      .forEach((activeObservation) => {
        console.log("active observation: ", activeObservation);
        // OPTICAL LAYERS
        if (activeObservation?.dataset?.details?.type === "optical") {
          layers.push(
            this.constructOpticalBasemapLayer({
              id: activeObservation.id,
              xyz_link: activeObservation.metadata.xyz_link,
              provider: activeObservation.metadata.provider,
            })
          );
        }

        // GEE LAYER
        if (
          activeObservation?.dataset?.details?.type ===
          "GoogleEarthEngineSatelliteLayer"
        ) {
          layers.push(this.constructGoogleEarthEngineLayer(activeObservation));
        }

        // GEOJSON LAYER
        if (activeObservation?.dataset?.details?.type === "GeoJsonLayer") {
          const data = readingsToGeojson(activeObservation.metadata?.readings);
          layers.push(
            constructBaseGeojsonLayer(data, {
              id: activeObservation.id,
              dataset: activeObservation.dataset,
              scaledMetric: activeObservation?.dataset?.details?.metric,
            })
          );
        }

        // SENSOR LAYER
        if (
          activeObservation.dataset.details.type === "SensorLayer" ||
          activeObservation.dataset.details.type === "SemLayer" ||
          activeObservation.dataset.details.type === "LgmsImpairedLayer"
        ) {
          layers.push(this.constructSensorLayer(activeObservation));
        }

        if (activeObservation.dataset.details.type === "LgmsImpairedLayer") {
          layers.push(this.constructLGMSlayer(activeObservation));
        }

        // V2 PATH SENSOR LAYER
        if (activeObservation.dataset.details.type === "PathSensorLayer") {
          layers.push(this.constructPathSensorLayer(activeObservation));
        }

        if (
          activeObservation.dataset.details.type === "PathSensorLegacyLayer"
        ) {
          layers.push(this.constructPathSensorLegacyLayer(activeObservation));
        }

        // GROUND SENSOR LAYER
        if (activeObservation.dataset.details.type === "GroundSensorLayer") {
          layers.push(this.constructGroundSensorLayer(activeObservation));
        }

        // LOCAL WIND LAYER
        if (activeObservation?.dataset?.details?.type === "localWindLayer") {
          layers.push(this.constructLocalWindLayer(activeObservation));
        }

        // SPECIAL PLUME LAYER
        if (activeObservation?.dataset?.details?.type === "PlumeLayer") {
          layers.push(this.constructPlumeLayer(activeObservation));
        }

        if (activeObservation?.dataset?.details?.type === "flightPathLayer") {
          layers.push(this.constructFlightPathLayer(activeObservation));
        }

        // SEM HEXAGON LAYER
        if (activeObservation?.dataset?.details?.type === "SemHeatMap") {
          layers.push(this.constructHexagonLayer(activeObservation));
        }

        // SEM FLUX HEXAGON LAYER
        if (activeObservation?.dataset?.details?.type === "SemFluxHeatMap") {
          layers.push(this.constructHexagonLayer(activeObservation));
        }

        // fallbackLayer
        if (activeObservation?.dataset?.details?.type === "fallbackLayer") {
          layers.push(
            ...this.constructFallbackVisualization(activeObservation)
          );
        }

        if (
          activeObservation?.dataset?.details?.type === "temporalContextLayer"
        ) {
          layers.push(
            this.constructTemporalContextVisualization(activeObservation)
          );
        }

        if (activeObservation?.dataset?.details?.type === "OTMLayer") {
          layers.push(...this.constructOTMVisualization(activeObservation));
        }

        // FALLBACK VISUALIZATION
        if (isEmpty(activeObservation.dataset.details)) {
          layers.push(
            ...this.constructFallbackVisualization(activeObservation)
          );
        }
      });

    // site layer outside of forEach because we always want to draw it.
    // layers.push(this.constructSiteLayer(this.props.site?.geometry));

    // we want context layers above all active observations loop
    // through them and push to the layer stack last
    this.props.activeContextLayers.forEach((layer) => {
      layers.push(this.constructContextLayer(layer));
    });

    // we want text layers on top of the site boundary
    // but, when two text layers are selected this tries to display both at the
    // same time and the text gets jumbled. TODO find another solution...
    // perhaps only display the last text layer added. make the others not
    // visible
    // layers = layers.sort((l) => (l.id.includes("text-layer") ? 1 : -1));

    console.log("newly computed deck.gl layers array:");
    console.log(layers);

    // loop through all layers and wrap everything in a promise so we can safely
    // use Promise.all() API to wait for both sync and async layers
    layers = layers.map((layer) => new Promise((resolve) => resolve(layer)));

    // some constructLayer functions return an array of deck.gl layers so we need to
    // flatten the array before visualizing with .flat()
    const resolvedLayers = await Promise.all(layers).then((layers) =>
      layers.flat()
    );

    // move plume orgin geojson layers to the very top of the map stack
    resolvedLayers.sort((layerA, layerB) => {
      if (layerA.props.plumeOriginLayer === layerB.props.plumeOriginLayer) {
        return 0;
      }
      if (layerA.props.plumeOriginLayer && !layerB.props.plumeOriginLayer) {
        return 1;
      }
      if (!layerA.props.plumeOriginLayer && layerB.props.plumeOriginLayer) {
        return -1;
      }
      return 0;
    });

    console.log("resolved layers");
    console.log(resolvedLayers);
    this.overlay.setProps({
      layers: [...resolvedLayers],
    });

    this.setState({ loading: false });
  }

  componentWillUnmount() {
    // hopefully JS garbage collection will free up memory once we set map to null.
    window.google.maps.event.clearListeners(this.map, "idle");
    window.google.maps.event.clearListeners(this.map, "click");
    window.google.maps.event.clearListeners(this.map, "mousemove");

    this.map = null;
    this.overlay.finalize();
  }

  constructSiteLayer(geometry) {
    if (geometry) {
      return constructBaseGeojsonLayer(geometry, {
        id: `site-layer`,
        filled: false,
        stroked: true,
        getFillColor: [29, 161, 242, 100],
        getLineColor: [218, 18, 125, 192],
        lineWidthScale: 1,
        getLineWidth: 10,
        lineWidthMinPixels: 10,
        lineWidthUnits: "pixels",
      });
    } else {
      return null;
    }
  }

  constructContextLayer(contextLayer) {
    console.log({ contextLayer });
    let data = contextLayer.data;

    if (data.features) {
      data = featureCollection(
        data.features.map((feature) => {
          const mutable = JSON.parse(JSON.stringify(feature));
          mutable.properties.layerTypeV2 = "ContextLayer";
          return mutable;
        })
      );
    }

    if (
      contextLayer.category === "LFG Extraction Wells" ||
      contextLayer.category === "GCCS Control Devices"
    ) {
      return constructBaseGeojsonLayer(data, {
        id: `context-layer-${contextLayer.id}`,
        filled: true,
        stroked: true,
        getLineColor: [255, 255, 255],
        getFillColor: () => contextLayer.color,
      });
    } else if (data.type === "MultiPolygon") {
      return constructBaseGeojsonLayer(data, {
        id: `context-layer-${contextLayer.id}`,
        filled: true,
        stroked: true,
        getLineColor: () =>
          contextLayer.color
            ? [...contextLayer.color, 192]
            : [28, 212, 212, 192],
        // multi-polygons need some fill to distinguish
        // inner & outer boundaries of multi-polygon.
        getFillColor: () => [...contextLayer.color, 50],
      });
    } else {
      return constructBaseGeojsonLayer(data, {
        id: `context-layer-${contextLayer.id}`,
        filled: false,
        stroked: true,
        getLineColor: () =>
          contextLayer.color
            ? [...contextLayer.color, 192]
            : [28, 212, 212, 192],
        // hack to increase onHover surface area
        getFillColor: () => [0, 0, 0, 0],
        getLineWidth: 5,
        getElevation: 30,
      });
    }
  }

  constructOTMVisualization(activeObservation) {
    console.log("OTM Layer Loaded");
    const value = activeObservation?.dataset?.metric?.metric
      ? activeObservation?.metadata?.[
          activeObservation?.dataset?.metric?.metric
        ]
      : activeObservation?.value;

    const colorValue = hexToRgb(
      computeStoneColor(activeObservation.dataset, value)
    );
    const centerFeature = centroid(this.props.site.geometry);
    //TODO: Consider creating new sensor layer type for OTM layer
    const data = feature(this.props.site.geometry, {
      layerType: "GroundSensorLayer",
      sensor_id: 0,
      name: "OTM System Data",
      hover_disabled: true,
      acquisitions: activeObservation.metadata.readings[0].acquisitions,
    });
    const textData = [
      {
        text: `${
          activeObservation.site_name
            ? `${activeObservation.site_name} :\n`
            : ""
        } ${formatData(value).value} ${
          activeObservation?.dataset?.metric?.units
        }`,
      },
    ];

    if (!this.props.activeObservations.includes(activeObservation.id)) {
      this.props.selectActiveSensor({
        ...data.properties,
        activeObservation: activeObservation,
      });
    }

    // this adds opacity value or a in rgb(a)
    // colorValue.push(255);
    if (this.props.site.geometry) {
      return [
        new GeoJsonLayer({
          id: `fill-layer-${activeObservation.id}`,
          data: data,
          pickable: true,
          filled: true,
          getFillColor: colorValue,
          onClick: (e) => {
            this.props.selectActiveSensor({
              ...e.object.properties,
              activeObservation: activeObservation,
            });
          },
        }),
        new TextLayer({
          id: `text-layer-${activeObservation.id}`,
          data: textData,
          dataComparator: isEqual,
          getAlignmentBaseline: "center",
          getAngle: 0,
          getPosition: () => centerFeature.geometry.coordinates,
          getSize: 20,
          getText: (d) => `${d.text}`,
          getTextAnchor: "middle",
          maxWidth: 1000,
          sizeScale: 1,
          sizeUnits: "pixels",
          wordBreak: "break-word",
          fontFamily: "Roboto",
          outlineWidth: 0.5,
          outlineColor: [255, 255, 255, 255],
          fontSettings: {
            sdf: true,
          },
        }),
      ];
    } else {
      return null;
    }
  }
  constructFallbackVisualization(activeObservation) {
    const value = activeObservation?.dataset?.metric?.metric
      ? activeObservation?.metadata?.[
          activeObservation?.dataset?.metric?.metric
        ]
      : activeObservation?.value;

    const colorValue = hexToRgb(
      computeStoneColor(activeObservation.dataset, value)
    );
    const centerFeature = centroid(this.props.site.geometry);
    const textData = [
      {
        text: `${
          activeObservation.site_name
            ? `${activeObservation.site_name} :\n`
            : ""
        } ${formatData(value).value} ${
          activeObservation?.dataset?.metric?.units
        }`,
      },
    ];

    // this adds opacity value or a in rgb(a)
    // colorValue.push(255);
    if (this.props.site.geometry) {
      return [
        new GeoJsonLayer({
          id: `fill-layer-${activeObservation.id}`,
          data: this.props.site.geometry,
          pickable: false,
          filled: true,
          getFillColor: colorValue,
        }),
        new TextLayer({
          id: `text-layer-${activeObservation.id}`,
          data: textData,
          dataComparator: isEqual,
          getAlignmentBaseline: "center",
          getAngle: 0,
          getPosition: () => centerFeature.geometry.coordinates,
          getSize: 20,
          getText: (d) => `${d.text}`,
          getTextAnchor: "middle",
          maxWidth: 1000,
          sizeScale: 1,
          sizeUnits: "pixels",
          wordBreak: "break-word",
          fontFamily: "Roboto",
          outlineWidth: 0.5,
          outlineColor: [255, 255, 255, 255],
          fontSettings: {
            sdf: true,
          },
        }),
      ];
    } else {
      return null;
    }
  }
  constructTemporalContextVisualization(activeObservation) {
    // this adds opacity value or a in rgb(a)
    // colorValue.push(255);
    const geoJsonData = createPoints(
      activeObservation.metadata.acquisitions,
      {}
    );
    if (activeObservation.metadata.geometry) {
      return [
        new GeoJsonLayer({
          id: `fill-layer-${activeObservation.id}`,
          data: activeObservation.metadata.geometry,
          pickable: false,
          filled: true,
          stroked: true,
          getLineColor: [0, 0, 0],
          lineWidthMinPixels: 2,
          getLineWidth: 5,
          getFillColor: [255, 185, 21, 150],
        }),
        new GeoJsonLayer({
          id: `fill-layer-${activeObservation.id}`,
          data: geoJsonData,
          pickable: false,
          filled: true,
          stroked: true,
          getLineColor: [0, 0, 0],
          lineWidthMinPixels: 2,
          getLineWidth: 5,
          getFillColor: [255, 185, 21, 150],
        }),
      ];
    } else {
      return null;
    }
  }

  async constructOpticalBasemapLayer({ id, xyz_link, provider }) {
    let xyz = xyz_link;
    const userToken = await auth.currentUser.getIdToken();
    let opticalProviderToken = this.props.opticalTokens[provider];
    if (provider === "airbus" && isTokenExpired(opticalProviderToken)) {
      opticalProviderToken = await fetch(
        `${process.env.REACT_APP_AIRLOGIC_API_URL}/tokens/${provider}`,
        {
          method: "GET",
          mode: "cors",
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        }
      ).then((response) => response.text());
    }

    if (provider === "maxar") {
      if (xyz.includes("maxar_api_key")) {
        xyz = updateUrlParameter(xyz, "maxar_api_key", opticalProviderToken);
      } else {
        xyz = `${xyz}&maxar_api_key=${opticalProviderToken}`;
      }
    }

    if (provider === "planet") {
      xyz = `${xyz_link}?api_key=${opticalProviderToken}`;
    }

    const options = {
      id,
    };

    if (provider === "airbus") {
      options.loadOptions = {
        fetch: {
          method: "GET",
          mode: "cors",
          headers: {
            Authorization: `Bearer ${opticalProviderToken}`,
          },
        },
      };
    }

    return constructBaseTileLayer(xyz, options);
  }

  async constructOpticalLayer(activeObservation) {
    let xyz = activeObservation.metadata.xyz_link;
    const opticalProviderToken =
      this.props.opticalTokens[activeObservation.metadata.provider];

    if (activeObservation.metadata.provider === "maxar") {
      if (xyz.includes("maxar_api_key")) {
        xyz = updateUrlParameter(xyz, "maxar_api_key", opticalProviderToken);
      } else {
        xyz = `${xyz}&maxar_api_key=${opticalProviderToken}`;
      }
    }

    if (activeObservation.metadata.provider === "planet") {
      xyz = `${activeObservation.metadata.xyz_link}?api_key=${opticalProviderToken}`;
    }

    const options = {
      id: activeObservation.id,
    };

    if (activeObservation.metadata.provider === "airbus") {
      options.loadOptions = {
        fetch: {
          method: "GET",
          mode: "cors",
          headers: {
            Authorization: `Bearer ${opticalProviderToken}`,
          },
        },
      };
    }

    return constructBaseTileLayer(xyz, options);
  }

  async constructOpticalPreviewLayer(activeObservation) {
    const userToken = await auth.currentUser.getIdToken();
    const opticalProviderToken = await fetch(
      `${process.env.REACT_APP_AIRLOGIC_API_URL}/tokens/${activeObservation.metadata.provider}`,
      {
        method: "GET",
        mode: "cors",
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      }
    ).then((response) => response.text());

    const bounds = activeObservation.metadata.img_geo;
    let thumbnail_url = `${activeObservation.metadata.thumbnail_link}`;
    if (activeObservation.metadata.provider === "maxar") {
      return new BitmapLayer({
        id: activeObservation.id,
        bounds: bounds,
        image: thumbnail_url,
        loadOptions: {
          fetch: {
            method: "GET",
            headers: {
              "MAXAR-API-KEY": opticalProviderToken,
            },
          },
        },
      });
    }
    if (activeObservation.metadata.provider === "planet") {
      thumbnail_url = `${activeObservation.metadata.thumbnail_link}?api_key=${opticalProviderToken}`;
    }
    if (activeObservation.metadata.provider === "airbus") {
      return new BitmapLayer({
        id: activeObservation.id,
        bounds: bounds,
        image: thumbnail_url,
        loadOptions: {
          fetch: {
            method: "GET",
            headers: {
              Authorization: `Bearer ${opticalProviderToken}`,
            },
          },
        },
      });
    }
    return new BitmapLayer({
      id: activeObservation.id,
      bounds: bounds,
      image: thumbnail_url,
    });
  }

  async constructPlumeLayer(activeObservation) {
    const data = createPoints(activeObservation.metadata.acquisitions, {
      layerType: activeObservation.dataset.details?.type,
      layerTypeV2: activeObservation.dataset.layer_type,
      scaledMetric: activeObservation.dataset.details?.metric,
    });
    // TODO - TEMP SOLUTION NEED UPDATING
    let fillColor = [218, 18, 125, 250];
    if (activeObservation.id.includes("bridger")) {
      fillColor = [62, 189, 147, 250];
    }
    const layers = [];

    try {
      layers.push(
        await this.constructGoogleEarthEngineLayer(activeObservation, {
          visible: this.props.showPlumeImagery,
          layerType: activeObservation.dataset.layer_type,
        })
      );
    } catch (error) {
      console.log("Error constructing tms for plume layer: ", error);
    }

    layers.push(
      constructBaseGeojsonLayer(data, {
        // not including activeObservation.id so that it is NOT
        // targeted when trying to adjust opacity of layer.
        id: `geojson-plume-origin-${activeObservation.datetime_str}`,
        getFillColor: fillColor,
        plumeOriginLayer: true,
      })
    );

    return layers;
  }

  constructHexagonLayer(activeObservation) {
    const data = [];
    Object.keys(activeObservation?.metadata?.hotspots).forEach((hex) => {
      data.push({
        hexagon: hex,
        properties: {
          layerType: activeObservation?.dataset?.details?.type,
          datetime: activeObservation.datetime_str,
          ...Object.keys(activeObservation?.metadata?.hotspots[hex]).reduce(
            (acc, cur) => {
              acc[cur] = activeObservation?.metadata?.hotspots[hex][cur];
              return acc;
            },
            {}
          ),
        },
      });
    });

    return constructBaseH3Layer(data, {
      id: activeObservation.id,
      dataset: activeObservation.dataset,
      scaledMetric: activeObservation?.dataset?.details.metric,
      onHover: ({ layer, object }) =>
        this.setState({
          hoveredInfo: {
            mapLayerType: activeObservation?.dataset?.details?.type,
            layer,
            object,
          },
        }),
    });
  }

  async constructGoogleEarthEngineLayer(activeObservation, options) {
    console.log("active observation: ");
    console.log(activeObservation);
    const { url } = await api.fetchObservationMapUrl({
      // doesn't appear that these bounds affect the url that is returned. TODO... figure out why
      geometry: activeObservation.site_geometry,
      end_date: activeObservation.datetime_end,
      start_date: activeObservation.datetime_start,
      dataset: activeObservation.dataset,
    });

    let properties = {
      id: activeObservation.id,
      maxZoom: activeObservation.dataset.details.maxZoom,
    };

    if (options) {
      properties = {
        ...properties,
        ...options,
      };
    }

    console.log("google earth engine layer url: ");
    console.log(url);
    console.log("google earth engine layer properties: ", properties);
    return constructBaseTileLayer(url, properties);
  }

  constructFlightPathLayer(activeObservation) {
    const scaledMetric = activeObservation?.dataset?.details?.metric;

    const data = createPoints(activeObservation.metadata.readings, {
      scaledMetric,
      layerType: activeObservation?.dataset?.details?.type,
      layerTypeV2: activeObservation.dataset.layer_type,
    });

    // create line layers from geojson points...
    // NOTE: unless we add a flight_id or something similar to metadata.readings there is no way to
    // distinguish between 2 flights with the same aircraft ID. they are plotted as 1 flight!
    const lines = {};
    data.features.forEach((feature, i, arr) => {
      if (
        arr[i + 1] !== undefined &&
        feature.properties.aircraft_id === arr[i + 1].properties.aircraft_id
      ) {
        const line_point = {
          aircraft_id: feature.properties.aircraft_id,
          from: {
            coordinates: feature.geometry.coordinates,
          },
          to: {
            coordinates: arr[i + 1].geometry.coordinates,
          },
        };
        if (!lines[feature.properties.aircraft_id]) {
          lines[feature.properties.aircraft_id] = [];
        }
        lines[feature.properties.aircraft_id].push(line_point);
      }
    });

    return [
      constructBaseGeojsonLayer(data, {
        id: activeObservation.id,
        dataset: activeObservation.dataset,
        scaledMetric,
        onHover: ({ layer, object }) =>
          this.setState({
            hoveredInfo: {
              mapLayerType: activeObservation.dataset?.details?.type,
              layer,
              object,
            },
          }),
      }),
      ...Object.keys(lines).map(
        (aircraftId) =>
          new LineLayer({
            id: `${activeObservation.id}-line`,
            data: lines[aircraftId],
            dataComparator: isEqual,
            pickable: false,
            getWidth: 3,
            getSourcePosition: (d) => d.from.coordinates,
            getTargetPosition: (d) => d.to.coordinates,
            getColor: (d) => {
              const color = computeColor(
                activeObservation?.dataset.details.vis,
                d[scaledMetric]
              );
              return hexToRgb(color);
            },
          })
      ),
    ];
  }

  constructPathSensorLayer(activeObservation) {
    const scaledMetric = activeObservation?.dataset?.details.metric;
    const readings = activeObservation.metadata.readings.reduce((acc, cur) => {
      const flattened = acc.concat(cur.acquisitions);
      return flattened;
    }, []);

    const points = createPoints(readings, {
      units: activeObservation?.dataset?.details?.units,
      layerTypeV2: activeObservation.dataset.layer_type,
      campaign: false,
      scaledMetric,
    });
    const campaigns = createPoints(
      activeObservation.metadata.readings.filter((reading) => {
        return reading.acquisitions.length > 1;
      }),
      {
        units: activeObservation.dataset.details?.units,
        layerTypeV2: activeObservation.dataset.layer_type,
        campaign: true,
        scaledMetric,
      }
    );
    return [
      constructBaseGeojsonLayer(campaigns, {
        id: `${activeObservation.id}-campaigns`,
        filled: false,
        stroked: true,
        dataset: activeObservation.dataset,
        getLineWidth: 10,
        getLineColor: (feature) => {
          const color = computeColor(
            activeObservation.dataset.metric.vis,
            feature.properties.value
          );
          return hexToRgb(color);
        },
      }),
      constructBaseGeojsonLayer(points, {
        scaledMetric,
        id: activeObservation.id,
        dataset: activeObservation.dataset,
        stroked: true,
        getLineColor: [255, 255, 255],
        getLineWidth: 3,
      }),
    ];
  }

  constructPathSensorLegacyLayer(activeObservation) {
    const scaledMetric = activeObservation?.dataset?.details.metric;
    const readings = activeObservation.metadata.campaigns.reduce((acc, cur) => {
      const merged = acc.concat(cur.acquisitions);
      return merged;
    }, []);
    const points = createPoints(readings, {
      scaledMetric,
      units: activeObservation?.dataset?.details?.units,
      layerType: activeObservation?.dataset?.details?.type,
      layerTypeV2: activeObservation.dataset.layer_type,
    });
    return [
      constructBaseGeojsonLayer(points, {
        id: activeObservation.id,
        dataset: activeObservation.dataset,
        scaledMetric,
        onClick: (e) => {
          this.props.selectActiveSensor({
            ...e.object.properties,
            activeObservation: activeObservation,
          });
        },
      }),
    ];
  }

  constructSensorLayer(activeObservation) {
    const scaledMetric = activeObservation?.dataset?.details.metric;
    const data = createPoints(activeObservation.metadata.readings, {
      scaledMetric,
      units: activeObservation?.dataset?.details?.units,
      layerType: activeObservation?.dataset?.details?.type,
      layerTypeV2: activeObservation.dataset.layer_type,
    });
    return constructBaseGeojsonLayer(data, {
      id: activeObservation.id,
      dataset: activeObservation.dataset,
      scaledMetric,
      // onClick: (e) => {
      //   this.props.selectActiveSensor({
      //     ...e.object.properties,
      //     activeObservation: activeObservation,
      //   });
      // },
    });
  }

  constructLGMSlayer(activeObservation) {
    const scaledMetric = activeObservation?.dataset?.details.metric;
    const data = createPoints(activeObservation.metadata.readings, {
      scaledMetric,
      units: activeObservation?.dataset?.details?.units,
      layerType: activeObservation?.dataset?.details?.type,
      layerTypeV2: activeObservation.dataset.layer_type,
    });
    return constructBaseGeojsonLayer(data, {
      id: activeObservation.id,
      dataset: activeObservation.dataset,
      scaledMetric,
      getFillColor: (d) => {
        const category = d.properties[scaledMetric]
          ? "Impaired"
          : "Non-impaired";
        const color = computeColor(
          activeObservation?.dataset.details.vis,
          category
        );
        return hexToRgb(color);
      },
    });
  }

  constructGroundSensorLayer(activeObservation) {
    const scaledMetric = activeObservation?.dataset?.details.metric;
    const data = createPoints(activeObservation.metadata.readings, {
      scaledMetric,
      units: activeObservation?.dataset?.details?.units,
      layerType: activeObservation?.dataset?.details?.type,
      layerTypeV2: activeObservation.dataset.layer_type,
    });
    return constructBaseGeojsonLayer(data, {
      id: activeObservation.id,
      dataset: activeObservation.dataset,
      scaledMetric,
      onClick: (e) => {
        this.props.selectActiveSensor({
          ...e.object.properties,
          activeObservation: activeObservation,
        });
      },
    });
  }

  constructLocalWindLayer(activeObservation) {
    const scaledMetric = activeObservation.dataset.details.metric;

    // quirk of pipeline atm. when it stops producing readings with NaNs we can remove these lines.
    const filtered = activeObservation.metadata.readings.filter(
      (reading) => !Number.isNaN(reading?.avg)
    );

    const data = createPoints(filtered, {
      scaledMetric,
      units: activeObservation.dataset.details?.units,
      datasetName: activeObservation.dataset.name,
      layerType: activeObservation.dataset.details?.type,
      layerTypeV2: activeObservation.dataset.layer_type,
      cadence: activeObservation.dataset.frequency,
    });

    return constructBaseGeojsonLayer(data, {
      id: activeObservation.id,
      dataset: activeObservation.dataset,
      scaledMetric,
      pointRadiusMinPixels: 10,
      onClick: ({ layer, object }) => {
        this.setState({
          hoveredInfo: {
            mapLayerType: activeObservation.dataset.details.type,
            layer,
            object,
          },
        });
      },
    });
  }

  getMapType() {
    if (this.map) {
      return this.map.getMapTypeId();
    }
  }

  getLayerThumbnail() {
    if (this.props.activeOpticalLayer) {
      return this.props.activeOpticalLayer.provider === "planet"
        ? `${this.props.activeOpticalLayer.thumbnail_link}?api_key=${this.props.opticalTokens["planet"]}`
        : this.props.activeOpticalLayer.thumbnail_link;
    } else {
      const imageMap = {
        roadmap: streetPng,
        satellite: satellitePng,
        terrain: terrainPng,
      };
      return imageMap[this.getMapType()];
    }
  }

  render() {
    return (
      <main
        className={"full-height-minus-header-and-sub-header bg-bsr-gray-100"}
        id="site-map-container"
      >
        <div className="h-full w-full" ref={this.mapRef}>
          {this.state.hoveredInfo && (
            <HoveredMapInfo
              mapLayerType={this.state.hoveredInfo?.mapLayerType}
              layer={this.state.hoveredInfo?.layer}
              clear={() => this.setState({ hoveredInfo: null })}
              properties={this.state.hoveredInfo?.object?.properties}
            />
          )}
        </div>
        <div
          onClick={() => this.zoomToActiveSite(this.props.site)}
          className={
            "absolute right-3 top-[175px] flex h-9 w-9 cursor-pointer items-center justify-center rounded-sm bg-white hover:bg-bsr-gray-200"
          }
        >
          <ArrowsPointingInIcon className="h-8 w-8 text-bsr-gray-500" />
        </div>
        <div
          onClick={this.props.toggleContextModal}
          className={classNames(
            this.props.contextModalOpen ||
              this.props.activeContextLayers.length > 0
              ? "bg-bsr-blue-400"
              : "bg-white hover:bg-bsr-gray-200",
            "absolute right-3 top-[265px] flex h-9 w-9 cursor-pointer items-center justify-center rounded-sm"
          )}
        >
          <Square3Stack3DIcon
            className={classNames(
              this.props.contextModalOpen ||
                this.props.activeContextLayers.length > 0
                ? "text-white"
                : "text-bsr-gray-500",
              "h-8 w-8"
            )}
          />
        </div>
        <div
          onClick={() => this.props.setShowLatLng((show) => !show)}
          className={classNames(
            this.props.showLatLng
              ? "bg-bsr-blue-400"
              : "bg-white hover:bg-bsr-gray-200",
            "absolute right-3 top-[220px] flex h-9 w-9 cursor-pointer items-center justify-center rounded-sm hover:bg-bsr-gray-200"
          )}
        >
          <ViewfinderCircleIcon
            className={classNames(
              this.props.showLatLng ? "text-white" : "text-bsr-gray-500",
              "h-8 w-8"
            )}
          />
        </div>
        {this.props.showLatLng && this.state.latLng && (
          <div className="absolute left-[90px] top-[80px] rounded-sm bg-bsr-gray-700 p-3 text-sm text-white opacity-75">
            {`${this.state.latLng ? this.state.latLng.lat().toFixed(6) : ""}, ${
              this.state.latLng ? this.state.latLng.lng().toFixed(6) : ""
            }`}
          </div>
        )}
        {this.props.contextModalOpen && (
          <div className="no-scrollbar absolute right-3 top-[310px] z-60 max-h-[350px] overflow-y-scroll rounded-md bg-bsr-gray-050">
            <ContextLayersPopup contextLayers={this.props.contextLayers} />
          </div>
        )}
        {
          <Popover className="absolute right-16 top-[80px]">
            <Popover.Button className="absolute right-0 top-0">
              <div
                style={{
                  backgroundImage: `linear-gradient(to top, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0) 100%),
              url(${this.getLayerThumbnail()})`,
                  backgroundSize: this.props.activeOpticalLayer
                    ? "300%"
                    : "cover",
                  backgroundPosition: "center",
                }}
                onClick={this.props.toggleOpticalModal}
                className="box-border flex h-[75px] w-[75px] cursor-pointer flex-col justify-end rounded-md border border-white text-xs text-white hover:border-2"
              >
                {this.props.activeOpticalLayer ? (
                  <div className="flex h-full flex-col items-center justify-between p-1">
                    <div className="flex justify-center font-bold">Basemap</div>
                    <div>
                      {dayjs(this.props.activeOpticalLayer.date).format(
                        "M-D-YYYY"
                      )}
                    </div>
                  </div>
                ) : (
                  <div className="mt-auto flex justify-center p-1">
                    {this.getMapType()}
                  </div>
                )}
              </div>
            </Popover.Button>
            <Popover.Panel className="relative right-[90px] z-90">
              {({ close }) => {
                return (
                  <OpticalLayersPopup
                    site={this.props.site}
                    activeOpticalLayer={this.props.activeOpticalLayer}
                    updateActiveOpticalLayer={
                      this.props.updateActiveOpticalLayer
                    }
                    opticalLayers={this.props.opticalLayers}
                    fetchOpticalLayers={this.props.fetchOpticalLayers}
                    getMapType={this.getMapType}
                    mapRef={this.map}
                    closeModal={close}
                  />
                );
              }}
            </Popover.Panel>
          </Popover>
        }
        {this.state.loading && <LoadingOverlay />}
      </main>
    );
  }
}

export default SiteMap;
