import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { auth } from "../shared/firebase";
import { parseWktPoint } from "../utilities";

export const measurementsApi = createApi({
  reducerPath: "measurements",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_AIRLOGIC_API_URL,
    prepareHeaders: (headers, { getState }) => {
      const token = getState().session.data.token;

      if (token) {
        headers.set("Authorization", `Bearer ${token}`);
      }
      return headers;
    },
  }),
  endpoints: (builder) => ({
    fetchMeasurements: builder.query({
      async queryFn(
        { start, end, siteIds },
        _queryApi,
        _extraOptions,
        baseQuery
      ) {
        const pageSize = 1000;
        let measurements = [];
        let locations = [];
        let nextPageToken = null;

        let url = `/accounts/${auth.currentUser.account}/measurements?page-size=${pageSize}`;

        if (start) {
          url += `&start=${start}`;
        }
        if (end) {
          url += `&end=${end}`;
        }
        if (siteIds && siteIds.length > 0) {
          siteIds.forEach((siteId) => {
            url += `&site-ids=${siteId}`;
          });
        }

        // Recursive fetch function
        const fetchPage = async (url) => {
          // Replace next_page_token if exists
          if (nextPageToken) {
            const urlObj = new URL(url, process.env.REACT_APP_AIRLOGIC_API_URL);
            urlObj.searchParams.set("start-after", nextPageToken); // Ensure only one start-after is added
            url = urlObj.toString();
          }

          const result = await baseQuery(url);

          // Handle any errors in the API response
          if (result.error) {
            return result;
          }

          const { data } = result;

          locations = [...locations, ...data.locations];
          measurements = [...measurements, ...data.measurements];

          nextPageToken = data.next_page_token;

          if (nextPageToken && data.measurements.length) {
            return fetchPage(url); // Fetch the next page
          }

          return {
            data: {
              measurements: measurements.map((m) => ({
                ...m,
                location: parseWktPoint(m.location_wkt),
              })),
              locations,
            },
          }; // Return all measurements when done
        };

        // Start fetching pages recursively
        return fetchPage(url);
      },
      // Cache settings
      keepUnusedDataFor: 900, // Keep cached data for 15 minutes (900 seconds)
    }),
    fetchMeasurementById: builder.query({
      async queryFn(
        { accountId, measurementId },
        _queryApi,
        _extraOptions,
        baseQuery
      ) {
        const url = `/accounts/${accountId}/measurements/${measurementId}`;

        const result = await baseQuery(url);

        // Handle any errors in the API response
        if (result.error) {
          return result;
        }

        const { data } = result;
        const parsedMeasurement = {
          ...data.measurement,
          location: parseWktPoint(data.measurement.location_wkt),
        };

        return { data: parsedMeasurement };
      },
      keepUnusedDataFor: 900, // Keep cached data for 15 minutes (900 seconds)
      providesTags: (result, error, { measurementId }) => [
        { type: "Measurement", id: measurementId },
      ],
    }),
  }),
});

// Export hooks generated by RTK Query
export const {
  useFetchMeasurementsQuery,
  useFetchMeasurementByIdQuery, // Hook for fetching individual measurement
} = measurementsApi;
