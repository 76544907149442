import React, { useEffect } from "react";
import { Routes, Route, useLocation, Navigate } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";

import PortfolioMapWrapper from "./PortfolioMapWrapper";
import PortfolioTrends from "./PortfolioTrends";
import SiteTrends from "./SiteTrends";
import SiteMapWrapper from "./SiteMapWrapper";
import CreateSite from "./shared/CreateSite";
import SiteSettings from "./SiteSettings";
import AppShell from "./AppShell";
import Pipe from "./shared/Pipe";
import Login from "./Login";
import Logout from "./Logout";
import LoginSso from "./LoginSso";
import Signup from "./Signup";
import NotFound from "./shared/NotFound";
import ResetPassword from "./ResetPassword";
import { RequireAuth } from "./shared/PrivateRoute";
import Redirect from "./Redirect";
import Notifications from "./shared/Notifications";
import AccountPage from "./AccountPage";
import DataCoverage from "./DataCoverage";
import TimeDropdown from "./TimeDropdown";
import SitesDropdown from "./SitesDropdown";
import DatasetsDropdown from "./DatasetsDropdown";
import ComposableSubNav from "./ComposableSubNav";
import Estimates from "./Estimates";
import MeasurementDetail from "./MeasurementDetail";
import Response from "./Response";
import Reports from "./Reports";
import Media from "./Media";

import { calculatePageName } from "./utilities";

import { fetchAccountResources } from "./store/applicationSlice";

if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
  // log environment
  console.log(
    `firebase project id: ${process.env.REACT_APP_FIREBASE_PROJECT_ID}`
  );
}

// when navigating from /trends to /trends/site,
//   this scrolls the user to the top of the page
//   that doesn't happen automatically
function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

export default function App() {
  const dispatch = useDispatch();
  const location = useLocation();

  const sessionStatus = useSelector((state) => state?.session?.status);
  const account = useSelector((state) => state.session.data?.account);

  // google analytics send page view on location change
  useEffect(() => {
    window.gtag("event", "page_view", {
      page_title: calculatePageName(location.pathname),
      page_location: window.location.href,
      page_path: location.pathname,
      account: account,
    });
  }, [location, account]);

  useEffect(() => {
    if (sessionStatus === "succeeded" && account) {
      dispatch(fetchAccountResources());
    }
  }, [dispatch, sessionStatus, account]);

  return (
    <div className="min-h-screen bg-gray-100">
      <Notifications />
      <Routes>
        <Route path="/login/sso" element={<LoginSso />} />
        <Route path="/login" element={<Login />} />
        <Route path="/signup" element={<Signup />} />
        <Route path="/logout" element={<Logout />} />
        <Route path="/reset" element={<ResetPassword />} />
        <Route path="/sites" element={<Navigate to="/map" replace />} />
        <Route
          path="/"
          element={
            <RequireAuth>
              <AppShell />
            </RequireAuth>
          }
        >
          <Route
            path="/response"
            element={
              <>
                <ComposableSubNav>
                  <TimeDropdown />
                  <Pipe />
                  <SitesDropdown />
                </ComposableSubNav>
                <Response />
              </>
            }
          ></Route>
          <Route
            path="/coverage"
            element={
              <>
                <ComposableSubNav>
                  <TimeDropdown />
                </ComposableSubNav>
                <DataCoverage />
              </>
            }
          ></Route>
          <Route
            path="/map"
            element={
              <>
                <ComposableSubNav>
                  <TimeDropdown />
                  <Pipe />
                  <SitesDropdown />
                  <Pipe />
                  <DatasetsDropdown />
                </ComposableSubNav>
                <PortfolioMapWrapper />
              </>
            }
          />
          <Route
            path="/trends"
            element={
              <>
                <ComposableSubNav>
                  <TimeDropdown />
                  <Pipe />
                  <SitesDropdown />
                  <Pipe />
                  <DatasetsDropdown />
                </ComposableSubNav>
                <PortfolioTrends />
              </>
            }
          />
          <Route
            path="/answers"
            element={<Navigate replace to="/answers/P6Enu5VXUIaSHJrf8HOUJ" />}
          />
          <Route path="/sites/new" element={<CreateSite />}></Route>
          <Route path="/sites/:siteId/edit" element={<SiteSettings />}></Route>
          <Route
            path="/trends/:siteId"
            element={
              <>
                <ComposableSubNav>
                  <TimeDropdown />
                  <Pipe />
                  <SitesDropdown />
                  <Pipe />
                  <DatasetsDropdown />
                </ComposableSubNav>
                <ScrollToTop />
                <SiteTrends />
              </>
            }
          />
          <Route
            path="/measurements/:measurementId"
            element={<MeasurementDetail />}
          ></Route>
          <Route
            path="/response/:siteId"
            element={
              <>
                <ComposableSubNav>
                  <TimeDropdown />
                  <Pipe />
                  <SitesDropdown />
                </ComposableSubNav>
                <Response />
              </>
            }
          ></Route>
          <Route
            path="/estimates"
            element={
              <>
                <ComposableSubNav>
                  <TimeDropdown />
                  <Pipe />
                  <SitesDropdown />
                </ComposableSubNav>
                <ScrollToTop />
                <Estimates />
              </>
            }
          />
          <Route
            path="/estimates/:siteId"
            element={
              <>
                <ComposableSubNav>
                  <TimeDropdown />
                  <Pipe />
                  <SitesDropdown />
                </ComposableSubNav>
                <ScrollToTop />
                <Estimates />
              </>
            }
          />
          <Route
            path="/map/:siteId"
            element={
              <>
                <ComposableSubNav>
                  <TimeDropdown />
                  <Pipe />
                  <SitesDropdown />
                  <Pipe />
                  <DatasetsDropdown />
                </ComposableSubNav>
                <ScrollToTop />
                <SiteMapWrapper />
              </>
            }
          />
          <Route
            path="/reports"
            element={
              <>
                <ComposableSubNav>
                  <TimeDropdown />
                </ComposableSubNav>
                <ScrollToTop />
                <Reports />
              </>
            }
          />
          <Route
            path="/media"
            element={
              <>
                <ComposableSubNav>
                  <TimeDropdown />
                </ComposableSubNav>
                <ScrollToTop />
                <Media />
              </>
            }
          />
          <Route path="/account" element={<AccountPage />} />
          <Route
            path="/support"
            element={
              <Redirect
                to={
                  process.env.REACT_APP_AIR_AWARE === "true"
                    ? "https://docs.google.com/document/d/1vAfHUWy95h0d_hc2S5LezsBjywNUBvCZMP57zW_52iw"
                    : "https://docs.google.com/document/d/1qtSFJOsICNsNXaRnrx7iBGkTSwzioJ5mBXstvNA9r5s"
                }
              />
            }
          ></Route>
        </Route>
        <Route path="*" element={<NotFound />} />
      </Routes>
    </div>
  );
}
