import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import "firebase/compat/storage";

var config = {
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  apiKey: process.env.REACT_APP_FIREBASE_APIKEY,
  databaseURL: process.env.REACT_APP_FIREBASE_DB,
  authDomain: process.env.REACT_APP_FIREBASE_AUTHDOMAIN,
  storageBucket: process.env.REACT_APP_FIREBASE_BUCKET,
};

firebase.initializeApp(config);

export const googleAuthProvider = new firebase.auth.GoogleAuthProvider();

// the firebase response is not pure JSON and cannot be serialzed by redux. So we extract the fields we need here:
export const serializeFirebaseUser = (firebaseUser) => {
  return {
    uid: firebaseUser.uid,
    account: firebaseUser.account,
    displayName: firebaseUser.displayName,
    email: firebaseUser.email,
    emailVerified: firebaseUser.emailVerified,
    isAnonymous: firebaseUser.isAnonymous,
    // metadata: {
    //   createTime: firebaseUser.metadata.createTime,
    //   lastSignInTime: firebaseUser.metadata.lastSignInTime,
    // },
    token: firebaseUser.token,
    phoneNumber: firebaseUser.phoneNumber,
    photoURL: firebaseUser.photoURL,
    tenantId: firebaseUser.tenantId,
  };
};

export const auth = firebase.auth();
export const db = firebase.firestore();
export const storage = firebase.storage();
