import React from "react";
import { MarkerClusterer } from "@googlemaps/markerclusterer";
import { GoogleMapsOverlay } from "@deck.gl/google-maps";
import { isEqual } from "lodash";
import { parse } from "wkt";
import { formatMeasurementValue } from "./utilities";
import { STATUS_COLORS } from "./constants";
import constructGeojsonLayer from "./layers/geojson";
import { feature } from "@turf/helpers";

class ResponseMap extends React.Component {
  constructor(props) {
    super(props);
    this.mapRef = React.createRef();
    this.markers = [];
    this.MAX_MARKERS_COUNT = 1000;
    this.state = {
      zoom: props.z || 5,
    };
  }

  async initMap() {
    const { Map } = await window.google.maps.importLibrary("maps");
    const { x, y, z } = this.props;

    if (this.mapRef.current) {
      const map = new Map(this.mapRef.current, {
        center: { lat: x || 39.8283, lng: y || -98.5795 },
        zoom: z || 5,
        mapId: process.env.REACT_APP_MAP_ID,
        fullscreenControl: false,
        streetViewControl: false,
        mapTypeControl: false,
        mapTypeId: "satellite",
      });

      map.addListener("idle", () => {
        const latLng = map.getCenter();
        const zoom = map.getZoom();

        this.props.searchParameters.set("x", latLng.lat());
        this.props.searchParameters.set("y", latLng.lng());
        this.props.searchParameters.set("z", zoom);

        this.props.setSearchParameters(this.props.searchParameters, {
          replace: true,
        });

        this.setState({ zoom }, () => {
          this.updateLocationsLayer(zoom); // Update GeoJSON layers based on zoom level
        });
      });

      // Set up the Deck.gl overlay with Google Maps
      this.overlay = new GoogleMapsOverlay({
        layers: [], // Initialize with an empty array
        getTooltip: ({ object }) => {
          if (object && object.properties) {
            return {
              html: `<div>${object.properties?.location_name}</div>`,
              style: {
                backgroundColor: "#616E7C",
                whiteSpace: "nowrap",
                fontSize: "1.5em",
                padding: "5px",
                color: "white",
              },
            };
          }
        },
      });
      this.overlay.setMap(map);

      return map;
    }
  }

  async componentDidMount() {
    const { AdvancedMarkerElement } = await window.google.maps.importLibrary(
      "marker"
    );

    this.AdvancedMarkerElement = AdvancedMarkerElement;
    this.map = await this.initMap();
    const processedMeasurements = this.processMeasurements();
    this.constructMeasurementsLayer(processedMeasurements);
    this.constructLocationsLayer();
    if (!this.props.x && !this.props.y && !this.props.z) {
      this.zoomToMarkersExtent();
    }
  }

  updateLocationsLayer(zoom) {
    const currentLayers = this.overlay.props.layers;

    const updatedLayers = currentLayers.map((layer) => {
      if (layer.id.includes("location-layer-site")) {
        return layer.clone({
          visible: zoom < 15 && zoom > 10,
        });
      }
      if (layer.id.includes("location-layer-location_group")) {
        return layer.clone({
          visible: zoom >= 15,
        });
      }
      return layer;
    });

    if (this.overlay) {
      this.overlay.setProps({
        layers: updatedLayers,
      });
    }
  }

  clearMarkers() {
    if (this.markerClusterer) {
      this.markerClusterer.clearMarkers(); // Clear the MarkerClusterer
      this.markerClusterer = null;
    }

    this.markers.forEach((marker) => marker.setMap(null)); // Clear the markers
    this.markers = [];
  }

  zoomToMarkersExtent() {
    if (!this.map || this.markers.length === 0) return; // Check if the map and markers are available

    const bounds = new window.google.maps.LatLngBounds();

    this.markers.forEach((marker) => {
      bounds.extend(marker.position); // Extend bounds to include each marker's position
    });

    this.map.fitBounds(bounds);
  }

  componentDidUpdate(prevProps) {
    if (!isEqual(prevProps.measurements, this.props.measurements)) {
      this.clearMarkers();
      const processedMeasurements = this.processMeasurements();
      this.constructMeasurementsLayer(processedMeasurements);
      this.zoomToMarkersExtent();
    }
  }

  addMarker(measurement) {
    if (!measurement.location) {
      return;
    }
    const status = measurement.tags
      .find((t) => t.group_id === "status")
      ?.tag_name.toUpperCase();

    const color = STATUS_COLORS?.[status] || "#9AA5B1";
    const svgMarker = document.createElement("div");
    svgMarker.innerHTML = `
      <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 10 10">
        <circle r="4" cx="5" cy="5" r="5" fill="${color}" stroke="white" stroke-width="2" />
      </svg>
    `;

    let zIndex;
    if (status === "NEEDS REVIEW") {
      zIndex = 300;
    } else if (status === "IN PROGRESS") {
      zIndex = 200;
    } else if (status === "COMPLETE") {
      zIndex = 100;
    } else {
      zIndex = 0; // Default zIndex for undefined statuses
    }

    svgMarker.style.zIndex = zIndex;

    const marker = new this.AdvancedMarkerElement({
      map: this.map,
      position: {
        lat: measurement.location.lat,
        lng: measurement.location.lng,
      },
      content: svgMarker,
    });

    marker.color = color;
    marker.status = status || "";

    // Create a buffered area for hover (larger invisible area for hover detection)
    const hoverBuffer = document.createElement("div");
    hoverBuffer.style.position = "absolute";
    hoverBuffer.style.width = "30px"; // Buffer size
    hoverBuffer.style.height = "30px";
    hoverBuffer.style.borderRadius = "50%"; // Making it a circle
    hoverBuffer.style.transform = "translate(-50%, -50%)"; // Center the buffer over the marker
    hoverBuffer.style.pointerEvents = "auto"; // Enable pointer events for hover
    hoverBuffer.style.backgroundColor = "rgba(255, 255, 255, 0)"; // Invisible

    // Append buffer area to marker content
    svgMarker.appendChild(hoverBuffer);

    // Create hover tooltip with caret
    const hoverTooltip = document.createElement("div");
    hoverTooltip.style.position = "absolute";
    hoverTooltip.style.padding = "5px";
    hoverTooltip.style.backgroundColor = "white";
    hoverTooltip.style.border = "1px solid #ccc";
    hoverTooltip.style.borderRadius = "3px";
    hoverTooltip.style.boxShadow = "0px 0px 5px rgba(0,0,0,0.2)";
    hoverTooltip.style.visibility = "hidden"; // Initially hidden
    hoverTooltip.style.whiteSpace = "nowrap"; // Avoid text wrapping
    hoverTooltip.innerHTML = `
      <div>${status ? status : ""}</div>
      <div>${formatMeasurementValue(measurement)}</div>
      <div style="width: 0; height: 0; border-left: 5px solid transparent; border-right: 5px solid transparent; border-top: 5px solid white; margin: 0 auto;"></div>
    `;

    // Add hoverTooltip near the marker
    hoverBuffer.appendChild(hoverTooltip);

    // Hover events
    hoverBuffer.addEventListener("mouseenter", () => {
      hoverTooltip.style.visibility = "visible";
      hoverTooltip.style.bottom = "25px"; // Position it above the marker
    });

    hoverBuffer.addEventListener("mouseleave", () => {
      hoverTooltip.style.visibility = "hidden";
    });

    marker.addListener("click", () => {
      this.props.navigate(`/measurements/${measurement.measurement_id}`);
    });

    this.markers.push(marker);

    return marker;
  }

  constructMeasurementsLayer(measurements) {
    this.markers = measurements
      .filter((m) => m.location !== null)
      .map((measurement) => {
        return this.addMarker(measurement);
      });

    this.markerClusterer = new MarkerClusterer({
      map: this.map,
      markers: this.markers,
      gridSize: 100, // Increase grid size to reduce aggressive clustering (default is 60)
      maxZoom: 12, // Stop clustering at higher zoom levels (optional)
      renderer: {
        render: ({ count, position, markers }) => {
          let color = "#9AA5B1";
          let highestPriority = 0; // 0 = default, 1 = complete, 2 = in progress, 3 = needs review

          for (const marker of markers) {
            if (marker.status === "NEEDS REVIEW" && highestPriority < 3) {
              color = STATUS_COLORS["NEEDS REVIEW"];
              highestPriority = 3; // Highest priority found
            } else if (marker.status === "IN PROGRESS" && highestPriority < 2) {
              color = STATUS_COLORS["IN PROGRESS"];
              highestPriority = 2; // Mid priority found
            } else if (marker.status === "COMPLETE" && highestPriority < 1) {
              color = STATUS_COLORS["COMPLETE"];
              highestPriority = 1; // Lowest priority found
            }
          }

          const svg = window.btoa(`
            <svg fill="${color}" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 240 240">
              <circle cx="120" cy="120" opacity=".6" r="70" />
              <circle cx="120" cy="120" opacity=".3" r="90" />
              <circle cx="120" cy="120" opacity=".2" r="110" />
              <circle cx="120" cy="120" opacity=".1" r="130" />
            </svg>`);
          // create marker using svg icon
          return new window.google.maps.Marker({
            position,
            icon: {
              url: `data:image/svg+xml;base64,${svg}`,
              scaledSize: new window.google.maps.Size(45, 45),
            },
            label: {
              text: String(count),
              color: "rgba(255,255,255,0.9)",
              fontSize: "12px",
            },
            // adjust zIndex to be above other markers
            zIndex: 1000 + count,
          });
        },
      },
    });
  }

  constructLocationsLayer() {
    const layers = this.props.locations.map((l) => {
      const {
        location_wkt,
        location_type,
        location_id,
        location_details,
        ...rest
      } = l;
      const geojson = parse(location_wkt);
      const data = feature(geojson, rest);
      return constructGeojsonLayer(data, {
        id: `location-layer-${location_type}-${location_id}`,
        stroked: true,
        pickable: true,
        filled: true,
        getLineWidth: 5,
        getLineColor: [193, 201, 196],
        getFillColor: [0, 0, 0, 0],
      });
    });
    if (this.overlay) {
      this.overlay.setProps({
        layers,
      });
    }
  }

  componentWillUnmount() {
    // hopefully JS garbage collection will free up memory once we set map to null.
    // TODO: keep an eye on memory usage and if we set up event listeners for this class component
    // be sure to un-register them here!
    this.map = null;
  }

  render() {
    return (
      <>
        {this.renderWarningBanner()}
        <div className="h-full w-full" ref={this.mapRef}></div>
      </>
    );
  }

  renderWarningBanner() {
    const { measurements } = this.props;
    if (measurements.length > this.MAX_MARKERS_COUNT) {
      return (
        <div className="bg-bsr-gray-300 bg-opacity-75 px-4 py-2 text-center text-sm text-white shadow-sm">
          Showing a sample of{" "}
          <span className="font-bold">
            {Math.min(this.MAX_MARKERS_COUNT, measurements.length)}
          </span>{" "}
          out of the <span className="font-bold">{measurements.length}</span>{" "}
          total measurements for performance purposes
        </div>
      );
    }
    return null;
  }

  processMeasurements() {
    const { measurements } = this.props;
    let processedMeasurements = measurements;

    if (measurements.length > 1000) {
      const samplingRate = Math.ceil(
        measurements.length / this.MAX_MARKERS_COUNT
      );
      processedMeasurements = measurements.filter(
        (_, index) => index % samplingRate === 0
      );
    }

    console.log({ processedMeasurements });

    return processedMeasurements;
  }
}

export default ResponseMap;
