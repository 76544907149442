import pako from "pako";
import firebase from "firebase/compat/app";
import { db, auth } from "../shared/firebase";
import { siteConverter } from "../SiteData";
import { contextLayerConverter } from "../ContextLayerData";
import { base64DecToArr, parseWktPoint } from "../utilities";
import promiseLimit from "promise-limit";
import { appendValueToAggregate, isNullish } from "../utilities";
import {
  ANSWERS_DATASET_MAP,
  CONTEXT_LAYER_CATEGORIES,
  DEFAULT_USER_SETTINGS,
} from "../constants";
import { parse } from "wkt";
import timestampConverter from "../converters/timestampConverter";

class BaseApi {
  constructor() {
    this._url =
      process.env.REACT_APP_AIRLOGIC_API_URL || "https://airlogic.io/api";
  }

  _fetchParse = async (method, url, body) => {
    const token = await auth.currentUser.getIdToken();
    const options = {
      method,
      mode: "cors",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    if (body) {
      options["body"] = JSON.stringify(body);
      options["headers"]["Content-Type"] = "application/json";
    }

    return fetch(url, options).then((response) => response.json());
  };

  _fetchAccountResource = async (collection, subcollection) => {
    const token = await auth.currentUser.getIdTokenResult();

    return db
      .collection(`${collection}/${token.claims.account_id}/${subcollection}`)
      .withConverter(siteConverter)
      .get()
      .then((snapshot) => {
        return snapshot.docs.map((doc) => ({ ...doc.data() }));
      });
  };
  _fetchAccountDoc = async (collection) => {
    const token = await auth.currentUser.getIdTokenResult();

    return db
      .collection(`${collection}`)
      .doc(token.claims.account_id)
      .get()
      .then((doc) => {
        return doc.data();
      });
  };
}

class Api extends BaseApi {
  fetchAccountSites = async () => {
    const token = await auth.currentUser.getIdTokenResult();

    return db
      .collection(`accounts/${token.claims.account_id}/sites`)
      .where("isDeleted", "==", false)
      .withConverter(siteConverter)
      .get()
      .then((snapshot) => {
        return snapshot.docs.map((doc) => ({ ...doc.data() }));
      });
  };
  // fetchAccountDatasets = async () => {
  //   const permissions = await this._fetchAccountDoc("permissions");
  //   const datasets = await Promise.all(
  //     permissions.layers.map((layerId) => {
  //       return db
  //         .collection("layers")
  //         .doc(layerId)
  //         .get()
  //         .then((doc) => doc.data());
  //     })
  //   );
  //   return datasets;
  // };
  fetchContextLayers = async (siteId) => {
    const token = await auth.currentUser.getIdTokenResult();
    const contextLayers = [];

    const queries = CONTEXT_LAYER_CATEGORIES.map((category) => {
      return db
        .collection(`accounts/${token.claims.account_id}/context_layers`)
        .where("site_id", "==", siteId)
        .where("category", "==", category)
        .orderBy("timestamp", "desc")
        .limit(1)
        .withConverter(contextLayerConverter)
        .get();
    });
    // Execute all queries concurrently
    await Promise.all(queries)
      .then((querySnapshots) => {
        querySnapshots.forEach((querySnapshot) => {
          querySnapshot.docs.forEach((doc) => {
            contextLayers.push({ ...doc.data(), id: doc.id });
          });
        });
      })
      .catch((error) => {
        console.error("Error getting context layers:", error);
      });

    return contextLayers;
  };

  fetchOptical = async ({ siteId, start, end, lat, lon }) => {
    console.group("==== getOptical ===");
    console.log("siteId", siteId);
    console.log("start", start);
    console.log("end", end);
    console.log("lat", lat);
    console.log("lon", lon);
    console.groupEnd();

    let urlString = `${this._url}/optical?site_ids=${siteId}&start=${start}&end=${end}`;

    if (!isNullish(lat) && !isNullish(lon)) {
      urlString += `&lat=${lat}&lon=${lon}`;
    }

    return this._fetchParse("GET", urlString);
  };

  fetchAccountSensors = async (account) => {
    return this._fetchParse(
      "GET",
      `${process.env.REACT_APP_AIRLOGIC_API_URL}/accounts/${account}/sensors`
    );
  };

  fetchAccountDatasets = async (account) => {
    return this._fetchParse(
      "GET",
      `${process.env.REACT_APP_AIRLOGIC_API_URL}/accounts/${account}/datasets`
    );
  };

  fetchAccountConfiguration = async (account) => {
    return this._fetchParse(
      "GET",
      `${process.env.REACT_APP_AIRLOGIC_API_URL}/accounts/${account}`
    );
  };

  fetchOpticalTokens = async () => {
    const providers = ["airbus", "planet", "maxar"];
    const { token } = await auth.currentUser.getIdTokenResult();
    const [airbus, planet, maxar] = await Promise.all(
      providers.map((provider) => {
        return fetch(
          `${process.env.REACT_APP_AIRLOGIC_API_URL}/tokens/${provider}`,
          {
            method: "GET",
            mode: "cors",
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
          .then((response) => response.text())
          .catch(() => {
            console.log(`failed to fetch token for ${provider}`);
            return null;
          });
      })
    );
    return { airbus, planet, maxar };
  };

  fetchSsoConfiguration = async (domain) => {
    return fetch(
      `${process.env.REACT_APP_AIRLOGIC_API_URL}/sso/${domain}`
    ).then((res) => {
      if (res.ok) {
        return res.json();
      }
      throw Error("invalid domain provided");
    });
  };

  fetchObservationMapUrl = async ({
    geometry,
    start_date,
    end_date,
    dataset,
  }) => {
    return this._fetchParse("POST", `${this._url}/tms`, {
      geometry,
      datetime_start: start_date,
      datetime_end: end_date,
      vis: {
        min: dataset.details.vis.domain[0],
        max: dataset.details.vis.domain[dataset.details.vis.domain.length - 1],
        palette: dataset.details.vis.range,
      },
      dataset_id: dataset.id,
    });
  };

  getMeasurementById = async ({ datasetId, measurementId }) => {
    return this._fetchParse(
      "GET",
      `${this._url}/tms/${datasetId}/measurements/${measurementId}`
    );
  };

  getMeasurementByAccountAndId = async ({ accountId, measurementId }) => {
    return this._fetchParse(
      "GET",
      `${this._url}/accounts/${accountId}/measurements/${measurementId}`
    );
  };

  fetchMeasurements = async ({ start, end, siteIds }) => {
    const { account } = auth.currentUser;
    const PAGE_SIZE = 1000;
    let base = `${this._url}/accounts/${account}/measurements?page-size=${PAGE_SIZE}`;

    if (start) {
      base += `&start=${start}`;
    }
    if (end) {
      base += `&end=${end}`;
    }

    if (siteIds && siteIds.length > 0) {
      siteIds.forEach((siteId) => {
        base += `&site-ids=${siteId}`;
      });
    }

    let total_measurements = [];

    const fetchRecursive = async () => {
      try {
        const { measurements, next_page_token, ...rest } =
          await this._fetchParse("GET", base);

        console.log(`fetched page with ${measurements.length} measurements`);

        total_measurements = total_measurements.concat(measurements);

        // empty measurements array means we have exhausted results
        if (measurements.length < PAGE_SIZE) {
          console.log(`fetched ${total_measurements.length} measurements`);
          return {
            ...rest,
            measurements: total_measurements.map((m) => ({
              ...m,
              location: parseWktPoint(m.location_wkt),
            })),
          };
        } else {
          if (base.includes("start-after")) {
            base = base.replace(
              /start-after=[^&]*/,
              `start-after=${next_page_token}`
            );
          } else {
            base += `&start-after=${next_page_token}`;
          }
          return await fetchRecursive(base); // Recursive call with next page token
        }
      } catch (error) {
        console.error("Failed to fetch measurements:", error);
        throw error;
      }
    };

    // Start the recursive fetching
    return await fetchRecursive(base);
  };

  fetchWind = async (measured_time, site_id, source) => {
    return this._fetchParse(
      "GET",
      `${this._url}/wind?measured_time=${measured_time}&site_id=${site_id}&source=${source}`
    );
  };

  fetchPriorDetections = async (locationId, end) => {
    const user = auth.currentUser;
    const url = `${this._url}/accounts/${user.account}/location/${locationId}/prior?end=${end}`;
    console.log({ url });
    return this._fetchParse("GET", url);
  };
}
const api = new Api();
export default api;

// TODO just noticed getObservations is not using CADENCE? is it possible we are aggregating
// observations from same datasets with different cadences??

export const checkDatasetsOptimized = async (
  { siteIds = [], datasetIds = [], cadence, startDate, endDate },
  dispatch
) => {
  const user = auth.currentUser;
  const limit = promiseLimit(500);

  console.time("coverage_query");
  let queryCount = 0;
  dispatch({ type: "check_started" });
  let queryDatasetIds = [...datasetIds];
  for (const siteId of siteIds) {
    const sitePromises = [];
    for (const datasetId of queryDatasetIds) {
      queryCount++;
      if (queryDatasetIds.length) {
        sitePromises.push(
          limit(() =>
            db
              .collection(
                `/accounts/${user.account}/sites/${siteId}/datasets/${datasetId}/observations`
              )
              .where("datetime_start", ">=", startDate)
              .where("datetime_start", "<", endDate)
              .limit(1)
              .get()
              .then((snapshot) => {
                dispatch({
                  type: "dataset_checked",
                  payload: { datasetId: datasetId, empty: snapshot.empty },
                });
                return snapshot;
              })
          )
        );
      }
    }
    const snapshots = await Promise.all(sitePromises);
    snapshots.forEach((snapshot) => {
      if (!snapshot.empty) {
        // queryDatasetIds.push(snapshot.docs[0].data().dataset_id);
        const index = queryDatasetIds.indexOf(
          snapshot.docs[0].data().dataset_id
        );
        if (index > -1) {
          queryDatasetIds.splice(index, 1);
        }
      }
    });
  }
  console.timeEnd("coverage_query");
  console.log({ queryCount });
  dispatch({ type: "check_complete" });
};

export const checkDatasets = async (
  { siteIds = [], datasetIds = [], cadence, startDate, endDate },
  dispatch
) => {
  console.log("check started");
  console.time("coverage_query");

  dispatch({ type: "check_started" });
  const limit = promiseLimit(500);
  const user = auth.currentUser;
  const uniqueDatasetIds = new Set(datasetIds);
  const promises = [];
  console.log({
    siteIds,
    datasetIds,
  });
  siteIds.forEach((siteId) => {
    uniqueDatasetIds.forEach((datasetId) => {
      promises.push(
        limit(() =>
          db
            .collection(
              `/accounts/${user.account}/sites/${siteId}/datasets/${datasetId}/observations`
            )
            .where("datetime_start", ">=", startDate)
            .where("datetime_start", "<", endDate)
            .limit(1)
            .get()
            .then((snapshot) => {
              dispatch({
                type: "dataset_checked",
                payload: { datasetId: datasetId, empty: snapshot.empty },
              });
            })
        )
      );
    });
  });

  await Promise.all(promises);
  console.timeEnd("coverage_query");
  console.log("# of queries");
  console.log(promises.length);
  dispatch({ type: "check_complete" });
};

export const getMeasurements = async ({
  siteIds,
  datasetIds,
  start,
  end,
  subsiteIntersect,
}) => {
  console.group("==== getMeasurements ===");

  console.log("siteIds", siteIds);
  console.log("datasetIds", datasetIds);

  if (!siteIds) {
    throw new Error("siteIds is required");
  }

  if (!datasetIds) {
    throw new Error("datasetIds is required");
  }

  const token = await auth.currentUser.getIdToken();
  const options = {
    method: "GET",
    mode: "cors",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const datasetParams = datasetIds.map((id) => `&dataset_ids=${id}`);
  let url = `${
    process.env.REACT_APP_AIRLOGIC_API_URL
  }/measurements?site_ids=${siteIds}${datasetParams.join("")}`;

  if (start) {
    url += `&start=${start}`;
  }

  if (end) {
    url += `&end=${end}`;
  }

  if (subsiteIntersect) {
    url += `&subsite_intersect=${subsiteIntersect}`;
  }

  console.log({ url });

  console.groupEnd();

  const payload = await fetch(url, options).then((response) => response.json());
  console.log({ payload });
  return payload;
};

export const getEstimates = async ({
  siteIds,
  datasetIds,
  start,
  end,
  subsiteIntersect,
}) => {
  const token = await auth.currentUser.getIdToken();
  const options = {
    method: "GET",
    mode: "cors",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  if (!siteIds) {
    throw new Error("siteIds is required");
  }

  if (!datasetIds) {
    throw new Error("datasetIds is required");
  }

  const datasetParams = datasetIds.map((id) => `&dataset_ids=${id}`);
  let url = `${
    process.env.REACT_APP_AIRLOGIC_API_URL
  }/estimates?site_ids=${siteIds}${datasetParams.join("")}`;

  if (start) {
    url += `&start=${start}`;
  }

  if (end) {
    url += `&end=${end}`;
  }

  if (subsiteIntersect) {
    url += `&subsite_intersect=${subsiteIntersect}`;
  }

  const payload = await fetch(url, options).then((response) => response.json());
  console.log({ payload });
  return payload;
};

export const getReported = async ({ account, siteId }) => {
  const datasetId = ANSWERS_DATASET_MAP[account].reported.dataset_id;
  const query = db
    .collection(
      `/accounts/${account}/sites/${siteId}/datasets/${datasetId}/observations`
    )
    // fetch most recent record in this collection
    .orderBy("datetime_start", "desc")
    .limit(1);

  const snapshot = await query.get();
  if (snapshot.empty) {
    return null;
  } else {
    const { datetime_start, datetime_end, ...rest } = snapshot.docs[0].data();
    return {
      ...rest,
      datetime_start: datetime_start.toDate().toISOString(),
      datetime_end: datetime_end.toDate().toISOString(),
    };
  }
};

export const getObservations = async ({
  siteIds = [],
  datasetIds = [],
  cadence,
  startDate,
  endDate,
} = {}) => {
  console.group("==== getObservations ===");
  console.log("siteIds", siteIds);
  console.log("datasetIds", datasetIds);
  console.log("cadence", cadence);
  console.log("startDate", startDate);
  console.log("endDate", endDate);
  console.groupEnd();

  const user = auth.currentUser;

  if (!user) {
    throw new Error("user is required");
  }
  if (!cadence) {
    throw new Error("cadence is required");
  }
  if (!startDate) {
    throw new Error("startDate is required");
  }
  if (!endDate) {
    throw new Error("endDate is required");
  }
  if (!datasetIds) {
    throw new Error("datasetIds is required");
  }

  const uniqueDatasetIds = new Set(datasetIds);

  // FLAT TABLE
  // TODO handle more than 10 datasets with multiple queries and promises.
  // TODO handle multiple sites with multiple queries and promises.
  //   let query = db
  //     .collection(`/accounts/${user.account}/observations`)
  //     .where("datetime_start", ">=", startDate)
  //     .where("datetime_start", "<", endDate)
  //     .where("cadence", "==", cadence)
  //     .where("dataset_id", "in", [...uniqueDatasetIds]);

  //   if (siteIds) {
  //     query = query.where("site_id", "==", siteIds[0]);
  //   }

  //   // TODO remove this limit when testing is completed
  //   query = query.limit(10000);
  //   const snapshot = await query.get();
  //   console.group("return value");
  //   console.log(
  //     `generated ${snapshot.size} reads. Cost $${(snapshot.size * 0.06) / 100000}`
  //   );

  //   const results = [];
  //   snapshot.forEach((doc) => {
  //     const { datetime_start, datetime_end, ...rest } = doc.data();
  //     results.push({
  //       ...rest,
  //       datetime_start: datetime_start.toDate().toISOString(),
  //       datetime_end: datetime_end.toDate().toISOString(),
  //     });
  //   });

  // NESTED TABLES (subcollections)
  const promises = [];

  siteIds.forEach((siteId) => {
    uniqueDatasetIds.forEach((datasetId) => {
      promises.push(
        db
          .collection(
            `/accounts/${user.account}/sites/${siteId}/datasets/${datasetId}/observations`
          )
          .where("datetime_start", ">=", startDate)
          .where("datetime_start", "<=", endDate)
          .withConverter(timestampConverter)
          .get()
      );
    });
  });

  const snapshots = await Promise.all(promises);

  const results = [];
  snapshots.forEach((snapshot) => {
    if (!snapshot.empty) {
      snapshot.docs.forEach((doc) => {
        try {
          const data = doc.data();

          let uncompressed_readings = null;
          let uncompressed_hotspots = null;
          let uncompressed_campaigns = null;

          if (data?.metadata?.readings_base64_gz) {
            const b64decodedArray = base64DecToArr(
              data?.metadata?.readings_base64_gz
            );
            const uncompressed_string = pako.inflate(b64decodedArray, {
              to: "string",
            });
            uncompressed_readings = JSON.parse(uncompressed_string);
          }
          if (data?.metadata?.hotspots_base64_gz) {
            const b64decodedArray = base64DecToArr(
              data?.metadata?.hotspots_base64_gz
            );
            const uncompressed_string = pako.inflate(b64decodedArray, {
              to: "string",
            });
            uncompressed_hotspots = JSON.parse(uncompressed_string);
          }
          if (data.metadata?.campaigns_base64_gz) {
            const b64decodedArray = base64DecToArr(
              data.metadata?.campaigns_base64_gz
            );
            const uncompressed_string = pako.inflate(b64decodedArray, {
              to: "string",
            });
            uncompressed_campaigns = JSON.parse(uncompressed_string);
          }
          const serialized = {
            ...data,
            metadata: {
              ...data.metadata,
              readings: uncompressed_readings
                ? uncompressed_readings
                : data?.metadata?.readings,
              campaigns: uncompressed_campaigns
                ? uncompressed_campaigns
                : data?.metadata?.campaigns,
              geometry: data?.metadata?.geometry
                ? parse(data?.metadata?.geometry)
                : null,
              hotspots: uncompressed_hotspots
                ? uncompressed_hotspots
                : data?.metadata?.hotspots,
            },
          };

          results.push(serialized);
        } catch (err) {
          console.log(`failed to parse/serialize observation:`);
          console.log(doc.data());
          console.error(err);
        }
      });
    }
  });

  console.log(
    `returned ${results.length} observations Cost $${
      (results.length * 0.06) / 100000
    }`
  );
  console.log("results", results);
  console.groupEnd();
  console.groupEnd();
  return results;
};

export const aggregateObservationsByMetric = ({
  observations,
  coverageObservations,
  metric,
  aggregation,
  name,
}) => {
  if (!observations) {
    return null;
  }

  if (!metric || !aggregation) {
    console.warn("value_field OR agg missing on dataset definition:");
    console.log(name);
  }

  const aggregated = {
    total: null,
    total_uncertainty: null,
    n: 0,
    sites_reporting: 0,
    sites_reporting_ids: new Set(),
    total_readings: 0,
  };

  observations.forEach((observation) => {
    // wrapping this in !isNullish() is important to not discarding zeros
    // we are removing it for now because we do not want to include zeros in ghgsat
    const value = observation?.[metric]
      ? observation?.[metric]
      : observation?.metadata?.[metric];

    const uncertainty = observation?.metadata?.uncertainty;

    const { total, total_uncertainty, n } = appendValueToAggregate(
      aggregation,
      aggregated.n,
      aggregated.total,
      aggregated.total_uncertainty,
      value,
      uncertainty
    );

    aggregated.total = total;
    aggregated.total_uncertainty = total_uncertainty;
    aggregated.n = n;
    aggregated.sites_reporting_ids.add(observation.site_id);
    aggregated.sites_reporting = aggregated.sites_reporting_ids.size;
    const total_readings = observation?.metadata?.total_readings
      ? observation?.metadata?.total_readings
      : observation?.metadata?.total_sensor_readings
      ? observation?.metadata?.total_sensor_readings
      : 0;
    aggregated.total_readings += total_readings;
  });

  if (coverageObservations) {
    coverageObservations.forEach((observation) => {
      const total_readings = observation?.value;

      if (total_readings > 0) {
        aggregated.total_readings += total_readings;
        aggregated.sites_reporting_ids.add(observation.site_id);
        aggregated.sites_reporting = aggregated.sites_reporting_ids.size;
      }
    });
  }

  return aggregated;
};

// obtaining thumbnails requires a different key for headers
export const fetchMaxarThumbnail = async (thumbnail_link, key) => {
  const options = {
    method: "GET",
    mode: "cors",
    headers: {
      "MAXAR-API-KEY": key,
      Accept: "image/jpeg",
    },
  };
  return fetch(thumbnail_link, options).then(async (response) => {
    const blob = await response.blob();

    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onloadend = () => {
        const base64data = reader.result;
        resolve(base64data);
      };
    });
  });
};

export async function hasObservations({ accountId, siteId, datasetId }) {
  return db
    .collection("accounts")
    .doc(accountId)
    .collection("sites")
    .doc(siteId)
    .collection("datasets")
    .doc(datasetId)
    .collection("observations")
    .limit(1)
    .get()
    .then((snapshot) => {
      return snapshot.docs.length === 0;
    });
}

export async function doesUserSettingsDocExist() {
  const { uid, account } = auth.currentUser;
  const userSettingsDoc = db.doc(`accounts/${account}/user_settings/${uid}`);

  try {
    const snapshot = await userSettingsDoc
      .withConverter(timestampConverter)
      .get();
    if (snapshot.exists) {
      return snapshot.data();
    } else {
      return false;
    }
  } catch (error) {
    console.error("Error checking if document exists:", error);
    throw new Error(error);
  }
}

export async function getUserSettingsDoc() {
  try {
    const { uid, account } = auth.currentUser;
    const userSettingsDocRef = db.doc(
      `accounts/${account}/user_settings/${uid}`
    );

    let userSettingsDoc = await userSettingsDocRef
      .withConverter(timestampConverter)
      .get();

    if (userSettingsDoc.exists) {
      return userSettingsDoc.data();
    } else {
      return DEFAULT_USER_SETTINGS;
    }
  } catch (err) {
    console.error("Failed to fetch user settings doc", err);
    throw new Error(err);
  }
}

export async function createUserSettingsDoc(account, uid, email, options = {}) {
  // Define the document path
  const docPath = `accounts/${account}/user_settings/${uid}`;
  const now = firebase.firestore.FieldValue.serverTimestamp();

  // Merge default settings with provided options
  const userSettings = {
    favorite_site_ids:
      options.favorite_site_ids || DEFAULT_USER_SETTINGS.favorite_site_ids,
    selected_site_ids:
      options.selected_site_ids || DEFAULT_USER_SETTINGS.selected_site_ids,
    user_email: email,
    user_uid: uid,
    created_ts: now,
    modified_ts: now,
    notifications_enabled:
      options.notifications_enabled ??
      DEFAULT_USER_SETTINGS.notifications_enabled,
    sites_subscription:
      options.sites_subscription || DEFAULT_USER_SETTINGS.sites_subscription,
    dataset_ids: options.dataset_ids || DEFAULT_USER_SETTINGS.dataset_ids,
  };

  try {
    // Get a reference to the document
    const docRef = db.doc(docPath);

    // Set the document with the user settings
    await docRef.set(userSettings);
    console.log("User settings document created successfully");
  } catch (error) {
    console.error("Error creating user settings document:", error);
    throw new Error("Error creating user settings document");
  }
}

// Function to favorite or unfavorite a site
export async function favoriteSite(siteId) {
  const { account, uid, email } = auth.currentUser;
  const docPath = `accounts/${account}/user_settings/${uid}`;

  try {
    const exists = await doesUserSettingsDocExist(account, uid);
    if (!exists) {
      await createUserSettingsDoc(account, uid, email);
    }

    // Get a reference to the document
    const docRef = db.doc(docPath);

    // Update the favoriteSites array with the new siteId
    await db.runTransaction(async (transaction) => {
      const doc = await transaction.get(docRef);

      if (!doc.exists) {
        throw new Error("Document does not exist!");
      }

      const favoriteSites = doc.data().favorite_site_ids;
      const siteIndex = favoriteSites.indexOf(siteId);

      if (siteIndex === -1) {
        favoriteSites.push(siteId);
      } else {
        favoriteSites.splice(siteIndex, 1);
      }

      transaction.update(docRef, { favorite_site_ids: favoriteSites });
    });

    console.log("Favorite site operation completed successfully");
  } catch (error) {
    console.error("Error favoriting site:", error);
    throw new Error("Error favoriting site");
  }
}

export async function getMeasurementById(id) {
  const { account } = auth.currentUser;
  const docRef = db.doc(`/accounts/${account}/measurements/${id}`);
  const doc = await docRef.withConverter(timestampConverter).get();
  const measurement = { id: doc.id, ...doc.data() };

  const notesRef = docRef.collection("notes");
  const notesSnapshot = await notesRef.withConverter(timestampConverter).get();
  const notes = notesSnapshot.docs.map((doc) => ({
    id: doc.id,
    ...doc.data(),
  }));

  return {
    measurement,
    notes,
  };
}

export async function addNoteToMeasurement({ measurementId, content }) {
  const { account, email, uid } = auth.currentUser;
  try {
    const notesRef = db
      .collection("accounts")
      .doc(account)
      .collection("measurements")
      .doc(measurementId)
      .collection("notes");

    const newNote = {
      type: "user",
      published: true,
      publisher: {
        email,
        uid,
      },
      content,
      published_time: firebase.firestore.FieldValue.serverTimestamp(), // Use Firestore serverTimestamp for consistent time
    };

    await notesRef.add(newNote);

    console.log("Note added successfully");
  } catch (error) {
    console.error("Error adding note:", error);
  }
}

export async function getNotesByMeasurementId(measurementId) {
  const { account } = auth.currentUser;
  try {
    const notesRef = db
      .collection("accounts")
      .doc(account)
      .collection("measurements")
      .doc(measurementId)
      .collection("notes");

    const querySnapshot = await notesRef.get();
    const notes = querySnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));

    return notes;
  } catch (error) {
    console.error("Error getting notes:", error);
    throw new Error("Failed to retrieve notes");
  }
}

export async function addTagToMeasurement(
  measurementId,
  tagToAdd,
  isStatusUpdate
) {
  const { account, email, uid } = auth.currentUser;

  try {
    const measurementRef = db
      .collection("accounts")
      .doc(account)
      .collection("measurements")
      .doc(measurementId);

    await db.runTransaction(async (transaction) => {
      const measurementDoc = await transaction.get(measurementRef);
      const existingTags = measurementDoc.data().tags || [];

      // Ensure there should only ever be one status tag per measurement
      let updatedTags = existingTags;
      if (isStatusUpdate) {
        updatedTags = existingTags.filter((tag) => tag.group_id !== "status");
      }

      updatedTags.push(tagToAdd);

      console.log({ updatedTags });

      transaction.update(measurementRef, {
        tags: updatedTags,
      });

      const noteContent = isStatusUpdate
        ? `Changed emission status to ${tagToAdd.tag_name}`
        : `Tag "${tagToAdd.tag_name}" added`;
      const note = {
        type: "tag",
        content: noteContent,
        published: true, // Assuming the note should be published immediately
        published_time: firebase.firestore.FieldValue.serverTimestamp(),
        publisher: {
          email: email,
          uid: uid,
        },
      };

      // Add the note to the notes subcollection
      const notesRef = measurementRef.collection("notes").doc();
      transaction.set(notesRef, note);
    });

    console.log("Tag and note added successfully in a transaction");
  } catch (error) {
    console.error("Error adding tag or note:", error);
    throw new Error("Failed to add tag or note");
  }
}

export async function removeTagFromMeasurement(
  measurementId,
  tagId,
  isStatusUpdate
) {
  const { account, email, uid } = auth.currentUser;

  try {
    const measurementRef = db
      .collection("accounts")
      .doc(account)
      .collection("measurements")
      .doc(measurementId);

    // Run transaction to remove the tag and add a note atomically
    await db.runTransaction(async (transaction) => {
      const measurementDoc = await transaction.get(measurementRef);

      if (!measurementDoc.exists) {
        throw new Error("Document does not exist!");
      }

      // Get the current tags array
      const currentTags = measurementDoc.data().tags || [];

      // Find the tag you want to remove
      const tagToRemove = currentTags.find((tag) => tag.tag_id === tagId);

      if (tagToRemove) {
        // Use arrayRemove to remove the exact tag object
        transaction.update(measurementRef, {
          tags: firebase.firestore.FieldValue.arrayRemove(tagToRemove),
        });
      }

      if (!isStatusUpdate) {
        // Create the note object
        const noteContent = `Tag "${tagToRemove.tag_name}" removed`;
        const note = {
          type: "tag",
          content: noteContent,
          published: true,
          published_time: firebase.firestore.FieldValue.serverTimestamp(),
          publisher: {
            email: email,
            uid: uid,
          },
        };

        const notesRef = measurementRef.collection("notes").doc();
        transaction.set(notesRef, note);
      }
    });

    console.log("Tag removed and note added successfully in a transaction");
  } catch (error) {
    console.error("Error removing tag or adding note:", error);
    throw new Error("Failed to remove tag or add note");
  }
}

export async function getAccountTags() {
  const { account } = auth.currentUser;
  try {
    const tagsCollectionRef = db
      .collection("accounts")
      .doc(account)
      .collection("tag_definitions");

    const snapshot = await tagsCollectionRef.get();

    if (snapshot.empty) {
      console.log("No tags found.");
      return [];
    }

    // Map over the documents and return their data
    const tags = snapshot.docs
      .map((doc) => ({
        id: doc.id, // Optional: Include the document ID
        ...doc.data(),
      }))
      .sort((a, b) =>
        a.tag_name.trim().toLowerCase().localeCompare(b.tag_name)
      );

    return tags;
  } catch (error) {
    console.log(error);
    console.error("Error fetching account tags:", error);
    throw error;
  }
}

export async function getAccountDatasets() {
  try {
    const datasetsRef = db.collection("datasets");
    const snapshot = await datasetsRef.withConverter(timestampConverter).get();

    if (snapshot.empty) {
      console.log("No datasets found.");
      return [];
    }

    const datasets = snapshot.docs.reduce((acc, cur) => {
      acc[cur.id] = cur.data();
      return acc;
    }, {});

    return datasets;
  } catch (error) {
    console.log(error);
    console.error("Error fetching account datasets:", error);
    throw error;
  }
}
