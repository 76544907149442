import { Link } from "react-router-dom";
import { ChevronRightIcon } from "@heroicons/react/24/outline";
import { convertToLocalTime } from "./utilities";

function formatValue(value) {
  try {
    if (value === null) {
      return "Non-detect";
    } else if (Number.isInteger(value)) {
      return value;
    } else {
      return value.toFixed(0);
    }
  } catch (err) {
    console.log(err);
    console.log("failed to parse measurement value");
    return "";
  }
}

export default function MeasurementHeader({ measurement, site, datasets }) {
  //get the site and timezone
  const timestamp = measurement?.measured_time;
  const percentError = (
    (measurement?.uncertainty_value / measurement?.value) *
    100
  ).toFixed(1);

  const tz = site?.timezone;
  const localTime = convertToLocalTime(timestamp, tz);

  return (
    <nav className="mx-auto flex-col justify-start truncate whitespace-nowrap">
      <div className="flex items-center text-sm text-bsr-gray-300 ">
        <Link className="hover:text-white" to="/response">
          Response
        </Link>
        <ChevronRightIcon className="h-5 w-8" />
        <Link className="hover:text-white" to={`/response/${site.id}`}>
          {site.name} {site.alternateSiteId ? `(${site.alternateSiteId})` : ""}
        </Link>
      </div>
      <div className="items-left flex pt-1">
        <p className="text-lg font-bold text-bsr-gray-300">
          Emission Detection
          <span className="ml-2 text-white">
            {localTime ? `${localTime}` : `${measurement?.measured_time} (UTC)`}
          </span>
          <span className="text-sm font-normal text-white">
            <span className="mx-1 text-bsr-gray-300">
              by{" "}
              {datasets?.[measurement?.dataset_id]?.dataset_name
                ? datasets[measurement.dataset_id].dataset_name
                : measurement.dataset_id}
            </span>

            <span className="text-lg font-bold">
              {formatValue(measurement.value)}
            </span>

            {measurement?.value ? (
              measurement?.uncertainty_value ? (
                <span className="ml-1">{`kg/hr ±${percentError}%`}</span>
              ) : (
                <span className="ml-1">kg/hr</span>
              )
            ) : (
              <span></span>
            )}
          </span>
        </p>
      </div>
    </nav>
  );
}
