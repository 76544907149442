import { GeoJsonLayer } from "deck.gl";
import { isEqual, merge, random } from "lodash";
import { computeColor, hexToRgb } from "../utilities";

const constructGeojsonLayer = (data, options = {}) => {
  if (!data) {
    console.warn(
      `WARNING: failed to supply required data property to constructGeojsonLayer ${options.id}`
    );
    return null;
  }
  // the property you want to use to programatically pick color for
  const metric = options.scaledMetric ? options.scaledMetric : "value";
  try {
    const baseOptions = {
      id: `geojson-${random(100)}`,
      data,
      dataComparator: isEqual,
      stroked: false,
      pickable: true,
      pointRadiusUnits: "pixels",
      lineWidthUnits: "pixels",
      getPointRadius: 5,
      getLineWidth: 3,
      getFillColor: (feature) => {
        const color = computeColor(
          options.dataset.details.vis,
          feature.properties[metric]
        );
        return hexToRgb(color);
      },
    };
    const mergedOptions = merge(baseOptions, options);

    return new GeoJsonLayer({
      ...mergedOptions,
    });
  } catch (err) {
    console.warn(
      `failed to create geojson layer for the following observation ${options.id}`
    );
    console.error(err);
    return null;
  }
};

export default constructGeojsonLayer;
