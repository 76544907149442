import dayjs from "./dayjs";
import { Line } from "react-chartjs-2";
import { useNavigate } from "react-router-dom";
import { buildObservationId, convertToUnits } from "./utilities";
import { ANSWERS_DATASET_MAP } from "./constants";
import * as JSURL from "jsurl";

const computeLinkUrl = (
  siteId,
  observationDate,
  observationDataset,
  observationLat,
  observationLon
) => {
  if (observationDataset === "sem2flux") {
    observationDataset = "wm-sem2flux-daily";
  }
  const baseUrl = `/map/${siteId}`;
  const observationParam = `${JSURL.stringify([
    buildObservationId(observationDataset, observationDate),
  ])}`;
  const timeframeParam = JSURL.stringify({
    name: `CUSTOM`,
    cadence: "daily",
    startDate: dayjs(observationDate).startOf("month").toISOString(),
    endDate: dayjs(observationDate).endOf("month").toISOString(),
  });
  const datasetsParam = JSURL.stringify([observationDataset]);

  // construct the lat and lon part of the url
  let latlonParamString = "";
  if (observationLat && observationLon) {
    latlonParamString = `&y=${observationLon}&x=${observationLat}&z=17`;
  }

  return `${baseUrl}?observations=${observationParam}&timeframe=${timeframeParam}&datasets=${datasetsParam}${latlonParamString}`;
};

function EstimatesChart({
  account,
  measurements,
  movingAverage,
  reported,
  siteId,
  datasets,
  activeTimePeriod,
  setFilteredDatasets,
}) {
  const navigate = useNavigate();
  const movingAverageFormatted = movingAverage.map((m) => {
    return { x: m.date, y: m.rolling_emission_kghr };
  });

  const upperUncertaintyBound = movingAverage.map((m) => {
    return { x: m.date, y: m.rolling_emission_kghr + m.rolling_std };
  });

  const lowerUncertaintyBound = movingAverage.map((m) => {
    return { x: m.date, y: m.rolling_emission_kghr - m.rolling_std };
  });

  const measurementsByDataset = measurements.reduce((acc, cur) => {
    if (!acc[cur.dataset]) {
      acc[cur.dataset] = {};
      acc[cur.dataset]["measurements"] = [cur];
      acc[cur.dataset]["count"] = 1;
    } else {
      acc[cur.dataset]["measurements"].push(cur);
      acc[cur.dataset]["count"] += 1;
    }
    return acc;
  }, {});
  let data = {
    datasets: [
      ...Object.keys(measurementsByDataset).map((dataset) => {
        return {
          type: "scatterWithErrorBars",
          label: datasets[dataset].name,
          data: measurementsByDataset[dataset].measurements.map((m) => {
            return {
              x: m.date,
              y: m.value,
              yMin: m.value - m.uncertainty,
              yMax: m.value + m.uncertainty,
              uncertainty: m.uncertainty,
              dataset: m.dataset,
              platform: m.platform,
              lat: m.latitude,
              lon: m.longitude,
              locationGroupId: m.location_group_id,
            };
          }),
          backgroundColor: datasets[dataset].color,
          errorBarColor: datasets[dataset].color,
          errorBarWhiskerColor: datasets[dataset].color,
          errorBarWhiskerSize: 7,
          radius: 5,
        };
      }),
      {
        label: "BSR Emissions Estimate",
        data: movingAverageFormatted,
        borderColor: "#2BB0ED",
        backgroundColor: "transparent",
        type: "line", // This will be a line plot
        fill: false,
        pointRadius: 0, // Hides the points
      },
      {
        label: "Upper Uncertainty",
        data: upperUncertaintyBound,
        borderColor: "#2BB0ED",
        backgroundColor: "transparent",
        type: "line", // This will be a line plot
        fill: false,
        pointRadius: 0, // Hides the points
        borderDash: [5, 2],
        borderWidth: 1,
      },
      {
        label: "Lower Uncertainy",
        data: lowerUncertaintyBound,
        borderColor: "#2BB0ED",
        backgroundColor: "transparent",
        type: "line", // This will be a line plot
        fill: false,
        pointRadius: 0, // Hides the points
        borderDash: [5, 2],
        borderWidth: 1,
      },
      // {
      //   type: "line",
      //   label: "Median measurement",
      //   data: [],
      //   backgroundColor: "#2BB0ED",
      //   borderWidth: 3,
      //   borderDash: [5, 2],
      // },
    ],
  };

  let options = {
    maintainAspectRatio: false,
    responsive: true,
    scales: {
      x: {
        type: "time",
        min: activeTimePeriod.startDate,
        max: activeTimePeriod.endDate,
        includeBounds: true,
        ticks: {
          maxTicksLimit: 10,
        },
      },
      y: {
        ticks: {
          callback: function (value) {
            return value + " kg/hr";
          },
        },
      },
    },
    onClick: (event, elements) => {
      const datasetIndex = elements[0].datasetIndex;
      const index = elements[0].index;
      const measurement = data.datasets[datasetIndex].data[index];
      try {
        // do nothing when user clicks on non-dataset chart items e.g. estimates curve
        if (measurement.dataset) {
          const link = computeLinkUrl(
            siteId,
            measurement.x,
            measurement.dataset,
            measurement.lat,
            measurement.lon
          );
          const [pathname, search] = link.split("?");
          return navigate({ pathname, search });
        }
      } catch (err) {
        console.warn(err);
        console.log(
          "failed to navigate to rosetta with the following measurement:"
        );
        console.log({
          siteId,
          date: measurement.x,
          dataset: measurement.dataset,
        });
      }
    },
    plugins: {
      legend: {
        onClick: (event, legendItem, legend) => {
          const index = legendItem.datasetIndex;
          const ci = legend.chart;
          const datasetId = Object.keys(datasets).reduce((acc, cur) => {
            if (datasets[cur].name === legendItem.text) {
              acc = cur;
            }
            return acc;
          }, "");
          if (ci.isDatasetVisible(index)) {
            ci.hide(index);
            legendItem.hidden = true;
            setFilteredDatasets((datasets) => [...datasets, datasetId]);
          } else {
            ci.show(index);
            legendItem.hidden = false;
            setFilteredDatasets((datasets) =>
              datasets.filter((id) => id !== datasetId)
            );
          }
        },
      },
      tooltip: {
        callbacks: {
          title: function (tooltipItems) {
            return dayjs(tooltipItems[0].raw.x).format("MMM DD, YYYY");
          },
          label: function (context) {
            let label = context.dataset.label || "";

            if (label && context.parsed.y !== null) {
              label += `: ${context.parsed.y.toFixed(2)} (kg/hr)`;
            }
            // if (context.raw.uncertainty !== null) {
            //   label += ` ±${context.raw.uncertainty.toFixed(2)}`;
            // }
            if (context.raw.platform !== undefined) {
              label += ` Platform: ${context.raw.platform}`;
            }
            if (
              context.raw.locationGroupId !== undefined &&
              context.raw.locationGroupId !== null
            ) {
              label += ` Facility ID: ${context.raw.locationGroupId}`;
            }
            return label;
          },
          afterLabel: function (tooltipItems) {
            let afterLabel = "";
            if (tooltipItems.parsed.y !== null) {
              afterLabel += `${convertToUnits(
                tooltipItems.parsed.y,
                "SCFM"
              ).toFixed(2)} (scfm)`;
            }
            return afterLabel;
          },
          footer: (tooltipItems) => {
            let footer = "";
            if (tooltipItems[0]?.raw?.dataset) {
              footer += datasets[tooltipItems[0].raw.dataset].name;
            }
            return footer;
          },
        },
      },
      annotation: {
        annotations: {},
      },
    },
  };

  if (reported) {
    data.datasets.push({
      type: "line",
      label: ANSWERS_DATASET_MAP[account].reported.name,
      data: [],
      backgroundColor: ANSWERS_DATASET_MAP[account].reported.color,
      borderWidth: 3,
      borderDash: [5, 2],
    });
    options.plugins.annotation.annotations["reported"] = {
      type: "line",
      mode: "horizontal",
      scaleID: "y",
      drawTime: "beforeDatasetsDraw",
      value: reported?.value ? reported.value : null,
      borderColor: ANSWERS_DATASET_MAP[account].reported.color,
      borderWidth: 3,
      borderDash: [4, 3],
    };
  }
  return <Line data={data} options={options} />;
}

export default EstimatesChart;
